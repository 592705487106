import { Platform, Switch } from 'react-native';

import { Text } from '../components/Text';
import { View } from '../components/View';
import { useI18n } from '../lib/i18n';
import { useTheme } from '../styles';

export function SwitchInput(props: {
  accessibilityLabel?: string;
  disabled?: boolean;
  showOnOff?: boolean;
  onChangeValue?: (value: boolean) => void;
  value?: boolean | null;
  testID?: string;
}) {
  const { theme } = useTheme();
  const { $t } = useI18n();
  return (
    <View
      spacing={8}
      row
      style={{
        opacity: props.disabled ? 0.5 : 1,
      }}
      accessible
      accessibilityRole="switch"
      accessibilityLabel={props.accessibilityLabel}
      accessibilityState={{
        checked: !!props.value,
        disabled: !!props.disabled,
      }}
      onAccessibilityTap={() => {
        props.onChangeValue?.(!props.value);
      }}
    >
      {props.showOnOff ? (
        <Text
          accessibilityRole="none"
          text={
            props.value
              ? $t({ id: 'SwitchInput_onLabel', defaultMessage: 'On' })
              : $t({ id: 'SwitchInput_offLabel', defaultMessage: 'Off' })
          }
          color={theme.color.gray400}
        />
      ) : null}
      <View
        style={{
          backgroundColor: props.value ? 'white' : theme.color.gray700,
          borderWidth: 2,
          paddingHorizontal: 1,
          borderRadius: 20,
          borderColor: props.value ? theme.color.primary100 : theme.color.gray600,
        }}
        importantForAccessibility="no-hide-descendants"
        accessibilityElementsHidden
      >
        <Switch
          value={!!props.value}
          thumbColor={props.value ? theme.color.primary100 : 'white'}
          trackColor={{ false: theme.color.gray700, true: 'white' }}
          onValueChange={props.onChangeValue}
          ios_backgroundColor="white"
          {...(Platform.OS === 'web' ? { activeThumbColor: theme.color.primary100 } : undefined)}
          testID={props.testID}
        />
      </View>
    </View>
  );
}
