import {
  Combobox,
  ComboboxInput,
  ComboboxList,
  ComboboxOption,
  ComboboxPopover,
} from '@reach/combobox';
import { ComponentProps, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { TextInput as RNTextInput, StyleSheet, TextInputProps } from 'react-native';
import { useDebounce } from 'use-debounce';

import { Text } from '../components/Text';
import { TextInput } from '../components/TextInput';
import { View } from '../components/View';
import { card } from '../styles';

function ComboboxInputAdapter(props: TextInputProps) {
  return (
    <ComboboxInput
      placeholder={props.placeholder}
      onFocus={props.onFocus}
      onBlur={props.onBlur}
      onChange={(e: any) => props.onChangeText?.(e.target.value)}
      style={StyleSheet.flatten([props.style, { borderStyle: 'solid' }])}
      data-nofocusoutline
      value={props.value}
    />
  );
}

// useDebounce doesn't seem to place nicely with fast refresh, so we may want to disable in DEV
const DISABLE_DEBOUNCE = false; // __DEV__

export function TypeaheadInput<T extends { value: string }>({
  attribution,
  onSearch,
  onSelect,
  renderItem,
  ...props
}: {
  attribution?: ReactNode;
  onSearch: (term: string) => Promise<T[]>;
  onSelect: (item: T) => unknown;
  renderItem?: (item: T, index: number) => ReactNode;
} & Omit<ComponentProps<typeof TextInput>, 'onChangeValue' | 'value'>) {
  const [search, setSearch] = useState('');
  const [options, setOptions] = useState<T[]>([]);
  // eslint-disable-next-line
  const [debouncedSearch] = DISABLE_DEBOUNCE ? [search] : useDebounce(search, 100);
  const debouncedSearchRef = useRef(debouncedSearch);
  debouncedSearchRef.current = debouncedSearch;
  const inputRef = useRef<RNTextInput>(null);

  useEffect(() => {
    onSearch(debouncedSearch).then((os) => {
      if (debouncedSearch === debouncedSearchRef.current) {
        setOptions(os);
      }
    });
  }, [onSearch, debouncedSearch]);

  const onSelectValue = useCallback(
    (value: string) => {
      const option = options.find((o) => o.value === value);
      if (option) {
        setSearch('');
        onSelect(option);
        inputRef.current?.blur();
      }
    },
    [onSelect, options],
  );

  return (
    <Combobox
      style={{ display: 'flex', flex: StyleSheet.flatten(props.style).flex, maxWidth: '100%' }}
      onSelect={onSelectValue}
    >
      <TextInput
        ref={inputRef}
        component={ComboboxInputAdapter}
        onChangeValue={setSearch}
        value={search}
        {...props}
        onFocus={(e) => {
          props.onFocus?.(e);
        }}
        onBlur={(e) => {
          props.onBlur?.(e);
        }}
      />
      {options.length ? (
        <ComboboxPopover>
          <View style={card}>
            <ComboboxList as="div">
              {options.map((op, i) => (
                <ComboboxOption key={op.value} value={op.value} as="div">
                  {renderItem?.(op, i) ?? (
                    <View style={{ padding: 10 }}>
                      <Text text={op.value} />
                    </View>
                  )}
                </ComboboxOption>
              ))}
            </ComboboxList>
            {attribution ? (
              <View style={{ paddingVertical: 4, justifyContent: 'center' }}>{attribution}</View>
            ) : null}
          </View>
        </ComboboxPopover>
      ) : null}
    </Combobox>
  );
}
