import { StyleProp, ViewStyle } from 'react-native';

import { Text } from '../components/Text';
import { View } from '../components/View';
import { Color } from '../styles';

const LINE_HEIGHT = 22;

function getBulletStyle(size: number) {
  return {
    borderRadius: Math.ceil(size / 2),
    width: size,
    height: size,
    marginTop: LINE_HEIGHT / 2 - size / 2 + 1,
  };
}

export const UnorderedList = ({
  color = Color.accent,
  items,
  customLabels,
  size = 'normal',
  style,
  testID,
  textColor = Color.styleGuide.Gray2,
  weight,
}: {
  color?: string;
  items: string[];
  customLabels?: { [key: string]: JSX.Element };
  size?: 'large' | 'normal' | 'small';
  style?: StyleProp<ViewStyle>;
  testID?: string;
  textColor?: string;
  weight?: 'normal';
}) => {
  return (
    <View
      spacing={size === 'small' ? 4 : 8}
      style={[{ alignSelf: 'stretch' }, style]}
      testID={testID}
    >
      {items.map((item, i) => (
        <View
          row
          key={item + i}
          spacing={size === 'large' ? 16 : 8}
          style={{ alignItems: 'flex-start' }}
        >
          <View
            style={[
              {
                ...getBulletStyle(8),
                backgroundColor: color,
              },
              size === 'small' ? getBulletStyle(5) : null,
              size === 'large' ? getBulletStyle(12) : null,
            ]}
          />
          <View style={{ flex: 1 }}>
            {customLabels?.[item] ?? (
              <Text
                text={item}
                size={size === 'large' ? 20 : size === 'small' ? 15 : 17}
                lineHeight={22}
                color={textColor}
                weight={weight ?? 'semibold'}
              />
            )}
          </View>
        </View>
      ))}
    </View>
  );
};
