import { EnvironmentSafety, PatientMyPlan } from '@oui/myplan/src/components/PatientMyPlan';

import { ActivityIndicator } from '@src/components/ActivityIndicator';
import { PatientMyStoryMyPlanContainer } from '@src/components/PatientMyStoryMyPlanContainer';
import { Text } from '@src/components/Text';
import { View } from '@src/components/View';
import {
  useMyStoryMyPlanCompositionSections,
  useMyStoryMyPlanCompositionSectionSubscriptionData,
} from '@src/hooks/useComposition';
import { useI18n } from '@src/lib/i18n';
import { card, useTheme } from '@src/styles';

export function PatientMyPlanReview() {
  const { $t } = useI18n();
  const { Color } = useTheme();
  const { data } = useMyStoryMyPlanCompositionSectionSubscriptionData();

  return (
    <PatientMyStoryMyPlanContainer
      heading={$t({ id: 'PatientMyPlanReview_heading', defaultMessage: 'MyPlan' })}
      testID="PatientMyPlanReview"
    >
      <View style={[card, { margin: 20, padding: 20, borderColor: Color.accent, borderWidth: 2 }]}>
        <View
          style={{
            paddingHorizontal: 20,
            paddingBottom: 10,
            marginBottom: 10,
            marginTop: -10,
            marginHorizontal: -20,
            borderBottomWidth: 1,
            borderColor: Color.styleGuide.Gray6,
          }}
        >
          <Text text="Safety steps" color={Color.styleGuide.Gray3} weight="semibold" />
        </View>
        {data ? <PatientMyPlan data={data} isEditing={false} onEdit={() => {}} /> : null}
      </View>
      <View style={[card, { margin: 20, padding: 20, borderColor: Color.accent, borderWidth: 2 }]}>
        {data ? <EnvironmentSafety data={data} isEditing={false} onEdit={() => {}} /> : null}
      </View>
    </PatientMyStoryMyPlanContainer>
  );
}

export function PatientMyPlanReviewStatic() {
  const { $t } = useI18n();
  const { Color } = useTheme();
  const { loading, data } = useMyStoryMyPlanCompositionSections();

  return (
    <PatientMyStoryMyPlanContainer
      heading={$t({ id: 'PatientMyPlanReview_heading', defaultMessage: 'MyPlan' })}
      testID="PatientMyPlanReview"
    >
      {loading ? (
        <ActivityIndicator />
      ) : (
        <>
          <View
            style={[card, { margin: 20, padding: 20, borderColor: Color.accent, borderWidth: 2 }]}
          >
            <View
              style={{
                paddingHorizontal: 20,
                paddingBottom: 10,
                marginBottom: 10,
                marginTop: -10,
                marginHorizontal: -20,
                borderBottomWidth: 1,
                borderColor: Color.styleGuide.Gray6,
              }}
            >
              <Text text="Safety steps" color={Color.styleGuide.Gray3} weight="semibold" />
            </View>
            {data ? <PatientMyPlan data={data} isEditing={false} onEdit={() => {}} /> : null}
          </View>
          <View
            style={[card, { margin: 20, padding: 20, borderColor: Color.accent, borderWidth: 2 }]}
          >
            {data ? <EnvironmentSafety data={data} isEditing={false} onEdit={() => {}} /> : null}
          </View>
        </>
      )}
    </PatientMyStoryMyPlanContainer>
  );
}
