import { useMutation } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import omit from 'lodash/omit';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Keyboard, SafeAreaView } from 'react-native';
import { AsyncReturnType } from 'type-fest';

import { CardStack, Card as CardStackCard } from '@oui/app-core/src/components/CardStack';
import { DiaryTabs } from '@oui/app-core/src/components/DiaryTabs';
import { PracticeRatingsInput } from '@oui/app-core/src/components/PracticeRatingInput';
import {
  RoundedSection,
  RoundedSectionNavigationOptions,
} from '@oui/app-core/src/components/RoundedSection';
import {
  PracticeFragment,
  ThoughtDiaryEntryFragment,
  useThoughtDiaryEntryPractice,
} from '@oui/app-core/src/hooks/practices';
import { getGQLDate } from '@oui/lib/src/getGQLDate';
import { graphql, VariablesOf } from '@oui/lib/src/graphql/tada';
import { RatingType } from '@oui/lib/src/types/graphql.generated';

import Background from '@src/assets/thoughtDiary/background.svg';
import SpotItIcon from '@src/assets/thoughtDiary/spotIt.svg';
import SwitchItIcon from '@src/assets/thoughtDiary/switchIt.svg';
import TestItIcon from '@src/assets/thoughtDiary/testIt.svg';
import { Button } from '@src/components/Button';
import { ConfirmationModal } from '@src/components/ConfirmationModal';
import { DateTimeInput } from '@src/components/DateTimeInput';
import { HeaderButtons, HeaderItem } from '@src/components/HeaderButtons';
import { ListItemTextInput } from '@src/components/ListItemTextInput';
import { ScrollView } from '@src/components/ScrollView';
import { SwitchInput } from '@src/components/SwitchInput';
import { Text } from '@src/components/Text';
import { TextInput } from '@src/components/TextInput';
import { View } from '@src/components/View';
import { useCurrentUser, useProgressByContent } from '@src/hooks/useCurrentUser';
import { useForm } from '@src/hooks/useForm';
import { EditThoughtDiaryEntryFormType } from '@src/lib/formTypes';
import { useDefaultHeaderHeight } from '@src/lib/getDefaultHeaderHeight';
import { IntlShape, useI18n } from '@src/lib/i18n';
import { type ThoughtDiaryEntriesQueryName } from '@src/screens/ThoughtDiary';
import { BeliefBubble, BeliefBubbleTopChild, SectionHeading } from '@src/screens/ThoughtDiaryEntry';
import { useTheme } from '@src/styles';
import { StackScreenProps } from '@src/types';

export const AddThoughtDiaryEntryMutation = graphql(
  `
    mutation AddThoughtDiaryEntry($input: AddThoughtDiaryEntryPracticeInput!) {
      saveThoughtDiaryEntry: addThoughtDiaryEntry(input: $input) {
        thoughtDiaryEntryPractice {
          ...PracticeFragment
          thoughtDiaryEntry {
            ...ThoughtDiaryEntryFragment
          }
        }
      }
    }
  `,
  [PracticeFragment, ThoughtDiaryEntryFragment],
);

export const UpdateThoughtDiaryEntryMutation = graphql(
  `
    mutation UpdateThoughtDiaryEntry($input: UpdateThoughtDiaryEntryPracticeInput!) {
      saveThoughtDiaryEntry: updateThoughtDiaryEntry(input: $input) {
        thoughtDiaryEntryPractice {
          ...PracticeFragment
          thoughtDiaryEntry {
            ...ThoughtDiaryEntryFragment
          }
        }
      }
    }
  `,
  [PracticeFragment, ThoughtDiaryEntryFragment],
);

function getThoughtDiaryRatingLabels($t: IntlShape['$t']) {
  return {
    '1': $t({ id: 'EditThoughtDiaryEntry_rating1', defaultMessage: 'Not at all' }),
    '2': $t({ id: 'EditThoughtDiaryEntry_rating2', defaultMessage: 'A little' }),
    '3': $t({ id: 'EditThoughtDiaryEntry_rating3', defaultMessage: 'Somewhat' }),
    '4': $t({ id: 'EditThoughtDiaryEntry_rating4', defaultMessage: 'Mostly' }),
    '5': $t({ id: 'EditThoughtDiaryEntry_rating5', defaultMessage: 'Completely' }),
  };
}

function CardLabel({ text, description }: { text: string; description: string | undefined }) {
  const { Color } = useTheme();
  return (
    <View
      accessibilityRole="none"
      style={{
        justifyContent: 'center',
        marginLeft: -16,
        marginBottom: 8,
        paddingHorizontal: 12,
      }}
      spacing={8}
    >
      <Text
        text={text}
        weight="semibold"
        size={21}
        textAlign="center"
        color={Color.styleGuide.Gray2}
      />
      {description ? (
        <Text text={description} textAlign="center" color={Color.styleGuide.Gray3} />
      ) : null}
    </View>
  );
}

const DEFAULT_RATING = 3;

function EditThoughtDiaryEntryInner(
  props: StackScreenProps<'EditThoughtDiaryEntry'> & { form: EditThoughtDiaryEntryFormType },
) {
  const { data: progress } = useProgressByContent();
  const testItUnlocked = !!progress.TEST_IT?.completion;
  const switchItUnlocked = !!progress.SWITCH_IT?.completion;
  const currentStep = props.route.params.step;
  const { Color } = useTheme();
  const { $t } = useI18n();

  const { data, bind, dirtyRef, clear } = props.form!;

  const dataRef = useRef(data);
  dataRef.current = data;
  const { setOptions, addListener } =
    useNavigation<StackScreenProps<'EditThoughtDiaryEntry'>['navigation']>();
  const [updateThoughtDiaryEntry] = useMutation(UpdateThoughtDiaryEntryMutation);
  const [addThoughtDiaryEntry] = useMutation(AddThoughtDiaryEntryMutation);

  const { setParams, navigate, replace } = props.navigation;

  const onSave = useCallback(() => {
    const isNewRecord = !props.route.params.practiceID;
    function afterSave(r: AsyncReturnType<typeof updateThoughtDiaryEntry>) {
      const ID = r.data?.saveThoughtDiaryEntry?.thoughtDiaryEntryPractice?.practiceID;
      if (ID) {
        clear(); // clearing due to beforeRemove listener
        if (isNewRecord) {
          replace('ThoughtDiaryEntry', {
            practiceID: ID,
          });
        } else {
          navigate('ThoughtDiaryEntry', {
            practiceID: ID,
          });
        }
      }
    }

    const ratings = [...dataRef.current.practiceValues.ratings];
    if (!ratings.find((r) => r.type === RatingType.RATING_BEFORE)) {
      ratings.push({ type: RatingType.RATING_BEFORE, value: DEFAULT_RATING });
    }
    if (switchItUnlocked && !ratings.find((r) => r.type === RatingType.RATING_AFTER)) {
      ratings.push({ type: RatingType.RATING_AFTER, value: DEFAULT_RATING });
    }

    return props.route.params.practiceID
      ? updateThoughtDiaryEntry({
          variables: {
            input: {
              practiceID: props.route.params.practiceID,
              ...dataRef.current,
              practiceValues: { ...dataRef.current.practiceValues, ratings },
            },
          },
          refetchQueries: ['ThoughtDiaryEntries' satisfies ThoughtDiaryEntriesQueryName],
        }).then(afterSave)
      : addThoughtDiaryEntry({
          variables: { input: dataRef.current },
          refetchQueries: ['ThoughtDiaryEntries' satisfies ThoughtDiaryEntriesQueryName],
        }).then(afterSave);
  }, [
    props.route.params.practiceID,
    updateThoughtDiaryEntry,
    addThoughtDiaryEntry,
    clear,
    replace,
    navigate,
    switchItUnlocked,
  ]);

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  useEffect(() => {
    const doneButton = (
      <Button
        text={$t({ id: 'EditThoughtDiaryEntry_doneButton', defaultMessage: 'Done' })}
        alignSelf="flex-start"
        onPress={onSave}
        testID="EditThoughtDiaryEntry_saveButton"
      />
    );

    if (currentStep === 'spot') {
      setOptions({
        title: $t({ id: 'EditThoughtDiaryEntry_addThoughtTitle', defaultMessage: 'Add thought' }),
        headerLeft: undefined,
        headerRight: () => (
          <HeaderButtons>
            {testItUnlocked ? (
              <Button
                text={$t({ id: 'EditThoughtDiaryEntry_nextButton', defaultMessage: 'Next' })}
                alignSelf="flex-start"
                onPress={() => setParams({ step: 'test' })}
                testID="EditThoughtDiaryEntry_nextButton"
              />
            ) : (
              doneButton
            )}
          </HeaderButtons>
        ),
      });
    } else if (currentStep === 'test') {
      setOptions({
        title: $t({ id: 'EditThoughtDiaryEntry_testThoughtTitle', defaultMessage: 'Test thought' }),
        headerLeft: ({ tintColor }) => (
          <HeaderButtons>
            <HeaderItem
              iconName="caret-left"
              accessibilityLabel={$t({
                id: 'EditThoughtDiaryEntry_backButtonAccessibilityLabel',
                defaultMessage: 'Back',
              })}
              title=""
              color={tintColor}
              onPress={() => setParams({ step: 'spot' })}
              testID="EditThoughtDiaryEntry_backButton"
            />
          </HeaderButtons>
        ),
        headerRight: () => (
          <HeaderButtons>
            {switchItUnlocked ? (
              <Button
                text={$t({ id: 'EditThoughtDiaryEntry_nextButton', defaultMessage: 'Next' })}
                testID="EditThoughtDiaryEntry_nextButton"
                alignSelf="flex-start"
                onPress={() => setParams({ step: 'switch' })}
              />
            ) : (
              doneButton
            )}
          </HeaderButtons>
        ),
      });
    } else if (currentStep === 'switch') {
      setOptions({
        title: $t({
          id: 'EditThoughtDiaryEntry_switchThoughtTitle',
          defaultMessage: 'Switch thought',
        }),
        headerLeft: ({ tintColor }) => (
          <HeaderButtons>
            <HeaderItem
              iconName="caret-left"
              accessibilityLabel={$t({
                id: 'EditThoughtDiaryEntry_backButtonAccessibilityLabel',
                defaultMessage: 'Back',
              })}
              title=""
              color={tintColor}
              onPress={() => setParams({ step: 'test' })}
              testID="EditThoughtDiaryEntry_backButton"
            />
          </HeaderButtons>
        ),
        headerRight: () => <HeaderButtons>{doneButton}</HeaderButtons>,
      });
    }
  }, [setParams, currentStep, setOptions, testItUnlocked, switchItUnlocked, onSave, $t]);

  useEffect(
    () =>
      addListener('beforeRemove', (e) => {
        if (!dirtyRef.current) {
          return;
        }

        e.preventDefault();

        setShowConfirmationModal(true);
      }),
    [addListener, dirtyRef],
  );

  let inner = null;

  const ratingBefore =
    data.practiceValues.ratings.find((v) => v.type === RatingType.RATING_BEFORE)?.value ?? 0;

  const ratingAfter =
    data.practiceValues?.ratings.find((v) => v.type === RatingType.RATING_AFTER)?.value ?? 0;

  if (props.route.params.cardStack) {
    if (currentStep === 'spot') {
      inner = (
        <CardStack hideNextButtonIndexes={[5]} key="spot">
          <CardStackCard _disableGesture>
            <DateTimeInput
              accessibilityLabel={$t({
                id: 'EditThoughtDiaryEntry_spot_dateAccessibilityLabel',
                defaultMessage: 'Date. When did this thought happen?',
              })}
              {...bind(['practiceValues', 'date'], {
                label: $t({
                  id: 'EditThoughtDiaryEntry_spot_dateLabel',
                  defaultMessage: 'Date',
                }),
              })}
              label={() => (
                <CardLabel
                  text={$t({
                    id: 'EditThoughtDiaryEntry_spot_dateLabel',
                    defaultMessage: 'Date',
                  })}
                  description={$t({
                    id: 'EditThoughtDiaryEntry_spot_dateDescription',
                    defaultMessage: 'When did this thought happen?',
                  })}
                />
              )}
              maximumDate={new Date()}
              mode="date"
            />
          </CardStackCard>
          <CardStackCard _disableGesture>
            <TextInput
              {...bind(['thoughtDiaryEntry', 'event'], {
                label: $t({
                  id: 'EditThoughtDiaryEntry_spot_eventLabel',
                  defaultMessage: 'Event',
                }),
              })}
              label={() => (
                <CardLabel
                  text={$t({
                    id: 'EditThoughtDiaryEntry_spot_eventLabel',
                    defaultMessage: 'Event',
                  })}
                  description={$t({
                    id: 'EditThoughtDiaryEntry_spot_eventDescription',
                    defaultMessage: 'What happened? Where were you?',
                  })}
                />
              )}
              accessibilityLabel={$t({
                id: 'EditThoughtDiaryEntry_spot_eventAccessibilityLabel',
                defaultMessage: 'Event. What happened? Where were you?',
              })}
              multiline
              inputStyle={{ minHeight: 110 }}
              placeholder={$t({
                id: 'EditThoughtDiaryEntry_spot_eventPlaceholder',
                defaultMessage: 'Describe the situation',
              })}
            />
          </CardStackCard>
          <CardStackCard _disableGesture>
            <TextInput
              {...bind(['thoughtDiaryEntry', 'thoughtBefore'], {
                label: $t({
                  id: 'EditThoughtDiaryEntry_spot_thoughtLabel',
                  defaultMessage: 'Thought',
                }),
              })}
              label={() => (
                <CardLabel
                  text={$t({
                    id: 'EditThoughtDiaryEntry_spot_thoughtLabel',
                    defaultMessage: 'Thought',
                  })}
                  description={$t({
                    id: 'EditThoughtDiaryEntry_spot_thoughtDescription',
                    defaultMessage: 'What thoughts went through your mind?',
                  })}
                />
              )}
              accessibilityLabel={$t({
                id: 'EditThoughtDiaryEntry_spot_thoughtAccessibilityLabel',
                defaultMessage: 'Thought. What thoughts went through your mind?',
              })}
              multiline
              inputStyle={{ minHeight: 110 }}
              placeholder={$t({
                id: 'EditThoughtDiaryEntry_spot_thoughtPlaceholder',
                defaultMessage: 'Write your thoughts',
              })}
            />
          </CardStackCard>
          <CardStackCard _disableGesture>
            <>
              <CardLabel
                text={$t({
                  id: 'EditThoughtDiaryEntry_spot_thoughtLabel',
                  defaultMessage: 'Thought',
                })}
                description={undefined}
              />
              <View
                style={{
                  backgroundColor: '#eee',
                  paddingHorizontal: 35,
                  marginHorizontal: -20,
                  paddingVertical: 15,
                }}
              >
                <Text text={`"${data.thoughtDiaryEntry.thoughtBefore}"`} textAlign="center" />
              </View>
              <Text
                text={$t({
                  id: 'EditThoughtDiaryEntry_spot_ratingBefore',
                  defaultMessage: 'How helpful is this thought to you?',
                })}
                weight="semibold"
                textAlign="center"
                style={{ marginHorizontal: 20, marginVertical: 20 }}
                accessibilityRole="none"
              />
              <PracticeRatingsInput
                ratingType={RatingType.RATING_BEFORE}
                defaultRating={DEFAULT_RATING}
                labels={getThoughtDiaryRatingLabels($t)}
                {...bind(['practiceValues', 'ratings'], {
                  accessibilityLabel: $t({
                    id: 'EditThoughtDiaryEntry_spot_ratingBeforeAccessibilityLabel',
                    defaultMessage: 'How helpful is this thought to you?',
                  }),
                })}
                testID="EditThoughtDiaryEntry_ratingBefore"
              />
            </>
          </CardStackCard>
          <CardStackCard _disableGesture>
            <BeliefBubbleTopChild>
              <BeliefBubble
                accordion
                rating={ratingBefore}
                text={data.thoughtDiaryEntry?.thoughtBefore ?? ''}
              />
            </BeliefBubbleTopChild>
            <CardLabel
              text={$t({
                id: 'EditThoughtDiaryEntry_spot_feelingLabel',
                defaultMessage: 'Feelings',
              })}
              description={$t({
                id: 'EditThoughtDiaryEntry_spot_feelingDescription',
                defaultMessage: 'What did you feel?',
              })}
            />
            <ListItemTextInput
              {...bind(['thoughtDiaryEntry', 'feelingsBefore'], {
                label: $t({
                  id: 'EditThoughtDiaryEntry_spot_feelingLabel',
                  defaultMessage: 'Feelings',
                }),
              })}
              label={undefined}
              accessibilityLabel={$t({
                id: 'EditThoughtDiaryEntry_spot_feelingAccessibilityLabel',
                defaultMessage: 'Feelings. What did you feel?',
              })}
              placeholder={$t({
                id: 'EditThoughtDiaryEntry_spot_feelingPlaceholder',
                defaultMessage: 'List emotions you felt',
              })}
              minHeight={110}
            />
          </CardStackCard>
          <CardStackCard _disableGesture>
            <BeliefBubbleTopChild>
              <BeliefBubble
                accordion
                rating={ratingBefore}
                text={data.thoughtDiaryEntry?.thoughtBefore ?? ''}
              />
            </BeliefBubbleTopChild>
            <TextInput
              {...bind(['thoughtDiaryEntry', 'behavior'], {
                label: $t({
                  id: 'EditThoughtDiaryEntry_spot_behaviorLabel',
                  defaultMessage: 'Behavior',
                }),
              })}
              label={() => (
                <CardLabel
                  text={$t({
                    id: 'EditThoughtDiaryEntry_spot_behaviorLabel',
                    defaultMessage: 'Behavior',
                  })}
                  description={$t({
                    id: 'EditThoughtDiaryEntry_spot_behaviorDescription',
                    defaultMessage: 'What did you do?',
                  })}
                />
              )}
              accessibilityLabel={$t({
                id: 'EditThoughtDiaryEntry_spot_behaviorAccessibilityLabel',
                defaultMessage: 'Behavior. What did you do?',
              })}
              multiline
              inputStyle={{ minHeight: 110 }}
              placeholder={$t({
                id: 'EditThoughtDiaryEntry_spot_behaviorPlaceholder',
                defaultMessage: 'Describe how you conducted yourself',
              })}
            />
            <Button
              testID={
                testItUnlocked
                  ? 'EditThoughtDiaryEntry_nextButton'
                  : 'EditThoughtDiaryEntry_saveButton'
              }
              text={$t({
                id: 'EditThoughtDiaryEntry_spot_doneButton',
                defaultMessage: 'Done',
              })}
              alignSelf="center"
              onPress={() => {
                if (testItUnlocked) {
                  setParams({ step: 'test' });
                  return;
                } else {
                  return onSave();
                }
              }}
            />
          </CardStackCard>
        </CardStack>
      );
    } else if (currentStep === 'test') {
      inner = (
        <CardStack hideNextButtonIndexes={[1]} key="test">
          <CardStackCard _disableGesture>
            <BeliefBubbleTopChild>
              <BeliefBubble
                accordion
                rating={ratingBefore}
                text={data.thoughtDiaryEntry?.thoughtBefore ?? ''}
              />
            </BeliefBubbleTopChild>
            <CardLabel
              text={$t({
                id: 'EditThoughtDiaryEntry_test_evidenceForLabel',
                defaultMessage: 'Evidence for',
              })}
              description={$t({
                id: 'EditThoughtDiaryEntry_test_evidenceForDescription',
                defaultMessage: 'What evidence makes you think this thought is true?',
              })}
            />
            <ListItemTextInput
              {...bind(['thoughtDiaryEntry', 'evidenceFor'], {
                label: $t({
                  id: 'EditThoughtDiaryEntry_test_evidenceForLabel',
                  defaultMessage: 'Evidence for',
                }),
              })}
              label={undefined}
              accessibilityLabel={$t({
                id: 'EditThoughtDiaryEntry_test_evidenceForAccesibilityLabel',
                defaultMessage: 'Evidence for. What evidence makes you think this thought is true?',
              })}
              placeholder={$t({
                id: 'EditThoughtDiaryEntry_test_evidenceForPlaceholder',
                defaultMessage: 'List your reasons for this thought',
              })}
              minHeight={110}
            />
          </CardStackCard>
          <CardStackCard _disableGesture>
            <BeliefBubbleTopChild>
              <BeliefBubble
                accordion
                rating={ratingBefore}
                text={data.thoughtDiaryEntry?.thoughtBefore ?? ''}
              />
            </BeliefBubbleTopChild>
            <CardLabel
              text={$t({
                id: 'EditThoughtDiaryEntry_test_evidenceAgainstLabel',
                defaultMessage: 'Evidence against',
              })}
              description={$t({
                id: 'EditThoughtDiaryEntry_test_evidenceAgainstDescription',
                defaultMessage:
                  "What facts or proof make you suspect that this isn't completely true?",
              })}
            />
            <ListItemTextInput
              {...bind(['thoughtDiaryEntry', 'evidenceAgainst'], {
                label: $t({
                  id: 'EditThoughtDiaryEntry_test_evidenceAgainstLabel',
                  defaultMessage: 'Evidence against',
                }),
              })}
              label={undefined}
              accessibilityLabel={$t({
                id: 'EditThoughtDiaryEntry_test_evidenceAgainstAccessibilityLabel',
                defaultMessage:
                  "Evidence against. What facts or proof make you suspect that this isn't completely true?",
              })}
              placeholder={$t({
                id: 'EditThoughtDiaryEntry_test_evidenceAgainstPlaceholder',
                defaultMessage: 'List your reasons against this thought',
              })}
              minHeight={110}
            />
            <Button
              testID={
                switchItUnlocked
                  ? 'EditThoughtDiaryEntry_nextButton'
                  : 'EditThoughtDiaryEntry_saveButton'
              }
              text={$t({
                id: 'EditThoughtDiaryEntry_test_doneButton',
                defaultMessage: 'Done',
              })}
              alignSelf="center"
              onPress={() => {
                if (switchItUnlocked) {
                  setParams({ step: 'switch' });
                  return;
                } else {
                  return onSave();
                }
              }}
            />
          </CardStackCard>
        </CardStack>
      );
    } else if (currentStep === 'switch') {
      inner = (
        <CardStack hideNextButtonIndexes={[2]}>
          <CardStackCard _disableGesture>
            <BeliefBubbleTopChild>
              <BeliefBubble
                accordion
                rating={ratingBefore}
                text={data.thoughtDiaryEntry?.thoughtBefore ?? ''}
              />
            </BeliefBubbleTopChild>
            <TextInput
              {...bind(['thoughtDiaryEntry', 'thoughtAfter'], {
                label: $t({
                  id: 'EditThoughtDiaryEntry_switch_thoughtAfterLabel',
                  defaultMessage: 'New thought',
                }),
              })}
              label={() => (
                <CardLabel
                  text={$t({
                    id: 'EditThoughtDiaryEntry_switch_thoughtAfterLabel',
                    defaultMessage: 'New thought',
                  })}
                  description={$t({
                    id: 'EditThoughtDiaryEntry_switch_thoughtAfterDescription',
                    defaultMessage:
                      'Given all of the evidence, is there a more helpful way of thinking about yourself or this situation?',
                  })}
                />
              )}
              accessibilityLabel={$t({
                id: 'EditThoughtDiaryEntry_switch_thoughtAfterAccessibilityLabel',
                defaultMessage:
                  'New thought. Given all of the evidence, is there a more helpful way of thinking about yourself or this situation?',
              })}
              multiline
              inputStyle={{ minHeight: 110 }}
              placeholder={$t({
                id: 'EditThoughtDiaryEntry_switch_thoughtAfterPlaceholder',
                defaultMessage: 'Try to form a fairer, more balanced way of summing this up',
              })}
            />
          </CardStackCard>
          <CardStackCard _disableGesture>
            <>
              <CardLabel
                text={$t({
                  id: 'EditThoughtDiaryEntry_switch_thoughtLabel',
                  defaultMessage: 'Thought',
                })}
                description={undefined}
              />
              <View
                style={{
                  backgroundColor: '#f1fcf4',
                  paddingHorizontal: 35,
                  marginHorizontal: -20,
                  paddingVertical: 15,
                }}
              >
                <Text text={`"${data.thoughtDiaryEntry.thoughtAfter}"`} textAlign="center" />
              </View>
              <Text
                text={$t({
                  id: 'EditThoughtDiaryEntry_switch_ratingAfter',
                  defaultMessage: 'How helpful is this new thought to you?',
                })}
                weight="semibold"
                textAlign="center"
                style={{ marginHorizontal: 20, marginVertical: 20 }}
                accessibilityRole="none"
              />
              <PracticeRatingsInput
                ratingType={RatingType.RATING_AFTER}
                defaultRating={DEFAULT_RATING}
                labels={getThoughtDiaryRatingLabels($t)}
                {...bind(['practiceValues', 'ratings'], {
                  accessibilityLabel: $t({
                    id: 'EditThoughtDiaryEntry_switch_ratingAfterAccessibilityLabel',
                    defaultMessage: 'How helpful is this thought to you?',
                  }),
                })}
                testID="EditThoughtDiaryEntry_ratingAfter"
              />
            </>
          </CardStackCard>
          <CardStackCard _disableGesture>
            <BeliefBubbleTopChild after>
              <BeliefBubble
                accordion
                after
                rating={ratingAfter}
                text={data.thoughtDiaryEntry?.thoughtAfter ?? ''}
              />
            </BeliefBubbleTopChild>
            <CardLabel
              text={$t({
                id: 'EditThoughtDiaryEntry_switch_feelingsLabel',
                defaultMessage: 'New feelings',
              })}
              description={$t({
                id: 'EditThoughtDiaryEntry_switch_feelingsDescription',
                defaultMessage: 'How do you feel when you consider the more balanced thought?',
              })}
            />
            <ListItemTextInput
              {...bind(['thoughtDiaryEntry', 'feelingsAfter'], {
                label: $t({
                  id: 'EditThoughtDiaryEntry_switch_evidenceAgainstLabel',
                  defaultMessage: 'Evidence against',
                }),
              })}
              label={undefined}
              accessibilityLabel={$t({
                id: 'EditThoughtDiaryEntry_switch_feelingsAccessibilityLabel',
                defaultMessage:
                  'New feelings. How do you feel when you consider the more balanced thought?',
              })}
              placeholder={$t({
                id: 'EditThoughtDiaryEntry_switch_feelingsPlaceholder',
                defaultMessage: 'List emotions you feel now',
              })}
              minHeight={110}
            />
            <Button
              testID="EditThoughtDiaryEntry_saveButton"
              text={$t({
                id: 'EditThoughtDiaryEntry_switch_doneButton',
                defaultMessage: 'Done',
              })}
              alignSelf="center"
              onPress={onSave}
            />
          </CardStackCard>
        </CardStack>
      );
    }
  } else {
    inner = (
      <ScrollView
        testID="EditThoughtDiaryEntry_scrollView"
        style={{
          flex: 1,
          backgroundColor: Color.grayBackground,
        }}
        contentContainerStyle={{
          paddingHorizontal: 20,
          paddingVertical: 28,
        }}
      >
        {currentStep === 'spot' ? (
          <View spacing={40}>
            <DateTimeInput
              label={$t({
                id: 'EditThoughtDiaryEntry_dateLabel',
                defaultMessage: 'Date',
              })}
              {...bind(['practiceValues', 'date'], {
                label: $t({
                  id: 'EditThoughtDiaryEntry_dateLabel',
                  defaultMessage: 'Date',
                }),
              })}
              maximumDate={new Date()}
              mode="date"
            />
            <View spacing={20}>
              <SectionHeading
                icon={<SpotItIcon accessibilityLabel={undefined} color={Color.styleGuide.Gray5} />}
                text={$t({
                  id: 'EditThoughtDiaryEntry_spotHeading',
                  defaultMessage: 'Spot it',
                })}
              />
              <TextInput
                {...bind(['thoughtDiaryEntry', 'event'], {
                  label: $t({
                    id: 'EditThoughtDiaryEntry_eventLabel',
                    defaultMessage: 'Event',
                  }),
                })}
                multiline
                inputStyle={{ minHeight: 110 }}
                placeholder={$t({
                  id: 'EditThoughtDiaryEntry_eventPlaceholder',
                  defaultMessage: 'Describe the situation',
                })}
              />
              <TextInput
                {...bind(['thoughtDiaryEntry', 'thoughtBefore'], {
                  label: $t({
                    id: 'EditThoughtDiaryEntry_thoughtLabel',
                    defaultMessage: 'Thought',
                  }),
                })}
                multiline
                inputStyle={{ minHeight: 110 }}
                placeholder={$t({
                  id: 'EditThoughtDiaryEntry_thoughtPlaceholder',
                  defaultMessage: 'Write your thoughts',
                })}
              />
              <View style={{ paddingHorizontal: 20 }}>
                <Text
                  text={$t({
                    id: 'EditThoughtDiaryEntry_helpful',
                    defaultMessage: 'How helpful is this thought to you?',
                  })}
                  weight="semibold"
                  textAlign="center"
                  style={{ marginHorizontal: 20, marginBottom: 20 }}
                  accessibilityRole="none"
                />
                <PracticeRatingsInput
                  ratingType={RatingType.RATING_BEFORE}
                  labels={getThoughtDiaryRatingLabels($t)}
                  {...bind(['practiceValues', 'ratings'], {
                    accessibilityLabel: $t({
                      id: 'EditThoughtDiaryEntry_ratingBeforePlaceholder',
                      defaultMessage: 'How helpful is this thought to you?',
                    }),
                  })}
                  testID="EditThoughtDiaryEntry_ratingBefore"
                />
              </View>
              <ListItemTextInput
                label={$t({
                  id: 'EditThoughtDiaryEntry_feelingsLabel',
                  defaultMessage: 'Feelings',
                })}
                {...bind(['thoughtDiaryEntry', 'feelingsBefore'], {
                  label: $t({
                    id: 'EditThoughtDiaryEntry_feelingsLabel',
                    defaultMessage: 'Feelings',
                  }),
                })}
                minHeight={110}
                placeholder={$t({
                  id: 'EditThoughtDiaryEntry_feelingsPlaceholder',
                  defaultMessage: 'List emotions you felt',
                })}
              />
              <TextInput
                placeholder={$t({
                  id: 'EditThoughtDiaryEntry_behaviorPlaceholder',
                  defaultMessage: 'Describe how you conducted yourself',
                })}
                multiline
                inputStyle={{ minHeight: 110 }}
                {...bind(['thoughtDiaryEntry', 'behavior'], {
                  label: $t({
                    id: 'EditThoughtDiaryEntry_behaviorLabel',
                    defaultMessage: 'Behavior',
                  }),
                })}
              />
            </View>
          </View>
        ) : currentStep === 'test' ? (
          <View spacing={20}>
            <BeliefBubble
              rating={ratingBefore}
              text={data.thoughtDiaryEntry?.thoughtBefore ?? ''}
            />
            <SectionHeading
              icon={<TestItIcon accessibilityLabel={undefined} color={Color.styleGuide.Gray5} />}
              text={$t({ id: 'EditThoughtDiaryEntry_testHeading', defaultMessage: 'Test it' })}
            />
            <ListItemTextInput
              label={$t({
                id: 'EditThoughtDiaryEntry_evidenceForLabel',
                defaultMessage: 'Evidence for',
              })}
              {...bind(['thoughtDiaryEntry', 'evidenceFor'], {
                label: $t({
                  id: 'EditThoughtDiaryEntry_evidenceForLabel',
                  defaultMessage: 'Evidence for',
                }),
              })}
              minHeight={110}
              placeholder={$t({
                id: 'EditThoughtDiaryEntry_evidenceForPlaceholder',
                defaultMessage: 'List your reasons for this thought',
              })}
            />
            <ListItemTextInput
              label={$t({
                id: 'EditThoughtDiaryEntry_evidenceAgainst',
                defaultMessage: 'Evidence against',
              })}
              {...bind(['thoughtDiaryEntry', 'evidenceAgainst'], {
                label: $t({
                  id: 'EditThoughtDiaryEntry_evidenceAgainst',
                  defaultMessage: 'Evidence against',
                }),
              })}
              minHeight={110}
              placeholder={$t({
                id: 'EditThoughtDiaryEntry_evidenceAgainstPlaceholder',
                defaultMessage: 'List your reasons against this thought',
              })}
            />
          </View>
        ) : (
          <View spacing={20}>
            <BeliefBubble
              rating={ratingBefore}
              text={data.thoughtDiaryEntry?.thoughtBefore ?? ''}
            />
            <SectionHeading
              icon={<SwitchItIcon accessibilityLabel={undefined} color={Color.styleGuide.Gray5} />}
              text={$t({ id: 'EditThoughtDiaryEntry_switchHeading', defaultMessage: 'Switch it' })}
            />
            <TextInput
              label={$t({
                id: 'EditThoughtDiaryEntry_thoughtAfterLabel',
                defaultMessage: 'New thought',
              })}
              {...bind(['thoughtDiaryEntry', 'thoughtAfter'], {
                label: $t({
                  id: 'EditThoughtDiaryEntry_thoughtAfterLabel',
                  defaultMessage: 'New thought',
                }),
              })}
              multiline
              inputStyle={{ minHeight: 110 }}
              placeholder={$t({
                id: 'EditThoughtDiaryEntry_thoughtAfterPlaceholder',
                defaultMessage: 'Try to form a fairer, more balanced way of summing this up',
              })}
            />
            <View style={{ paddingHorizontal: 20 }}>
              <Text
                text={$t({
                  id: 'EditThoughtDiaryEntry_ratingAfter',
                  defaultMessage: 'How helpful is this new thought to you?',
                })}
                weight="semibold"
                textAlign="center"
                style={{ marginHorizontal: 20, marginBottom: 20 }}
                accessibilityRole="none"
              />
              <PracticeRatingsInput
                ratingType={RatingType.RATING_AFTER}
                labels={getThoughtDiaryRatingLabels($t)}
                {...bind(['practiceValues', 'ratings'], {
                  accessibilityLabel: $t({
                    id: 'EditThoughtDiaryEntry_ratingAfterAccessibilityLabel',
                    defaultMessage: 'How helpful is this new thought to you?',
                  }),
                })}
                testID="EditThoughtDiaryEntry_ratingAfter"
              />
            </View>
            <ListItemTextInput
              label={$t({
                id: 'EditThoughtDiaryEntry_feelingsAfterLabel',
                defaultMessage: 'New feelings',
              })}
              {...bind(['thoughtDiaryEntry', 'feelingsAfter'], {
                label: $t({
                  id: 'EditThoughtDiaryEntry_feelingsAfterLabel',
                  defaultMessage: 'New feelings',
                }),
              })}
              minHeight={110}
              placeholder={$t({
                id: 'EditThoughtDiaryEntry_feelingsAfterPlaceholder',
                defaultMessage: 'List emotions you feel now',
              })}
            />
          </View>
        )}
      </ScrollView>
    );
  }

  return (
    <>
      {inner}
      {showConfirmationModal ? (
        <ConfirmationModal
          confirmTestID="EditThoughtDiaryEntryInner_confirmationModalSaveButton"
          cancelTestID="EditThoughtDiaryEntryInner_confirmationModalDiscardButton"
          onCancel={() => {
            setShowConfirmationModal(false);
            clear();
            navigate('ThoughtDiary', {});
          }}
          onConfirm={() => {
            return onSave();
          }}
          visible={showConfirmationModal}
          confirmText={$t({
            id: 'EditThoughtDiaryEntry_confirmationModalSaveButton',
            defaultMessage: 'Save',
          })}
          cancelText={$t({
            id: 'EditThoughtDiaryEntry_confirmationModalDiscardButton',
            defaultMessage: 'Discard',
          })}
          title={$t({
            id: 'EditThoughtDiaryEntry_confirmationModalTitle',
            defaultMessage: 'Save changes?',
          })}
          description={$t({
            id: 'EditThoughtDiaryEntry_confirmationModalDescription',
            defaultMessage:
              "You've added info to this entry. Would you like to save it before leaving?",
          })}
        />
      ) : null}
    </>
  );
}

export function EditThoughtDiaryEntry(props: StackScreenProps<'EditThoughtDiaryEntry'>) {
  const defaultHeaderHeight = useDefaultHeaderHeight();
  const { Color } = useTheme();
  const isCardView = !!props.route.params.cardStack;
  const { setOptions } = useNavigation<StackScreenProps<'EditThoughtDiaryEntry'>['navigation']>();
  const { data: progress } = useProgressByContent();
  const testItUnlocked = !!progress.TEST_IT?.completion;
  const switchItUnlocked = !!progress.SWITCH_IT?.completion;
  // When keyboard is open, we need to make sure that the space taken up by the Visual component
  // doesn't prevent the Chat from moving up high enough to avoid the keyboard.
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);
  const { $t } = useI18n();
  useEffect(() => {
    function _keyboardDidShow() {
      setIsKeyboardOpen(true);
    }
    function _keyboardDidHide() {
      setIsKeyboardOpen(false);
    }

    const removeDidShow = Keyboard.addListener('keyboardDidShow', _keyboardDidShow);
    const removeDidHide = Keyboard.addListener('keyboardDidHide', _keyboardDidHide);

    return () => {
      removeDidShow.remove();
      removeDidHide.remove();
    };
  }, []);

  useEffect(() => {
    if (isCardView) {
      const options = RoundedSectionNavigationOptions({
        defaultHeaderHeight,
        tintColor: Color.text,
        backgroundColor: '#c1e3c9',
      });
      setOptions({ ...options, headerRight: undefined });
    } else {
      setOptions({
        headerTintColor: Color.accent,
        headerStyle: {},
        headerTitle: $t({
          id: 'EditThoughtDiaryEntry_formViewHeader',
          defaultMessage: 'Edit thought',
        }),
      });
    }
  }, [isCardView, setOptions, props, Color, defaultHeaderHeight, $t]);

  const { data: user } = useCurrentUser();
  const { thoughtDiaryEntry, practiceValues } = useThoughtDiaryEntryPractice();
  const [today] = useState(getGQLDate);
  const form = useForm<VariablesOf<typeof AddThoughtDiaryEntryMutation>['input']>(
    {
      practiceValues: {
        patientID: user?.currentUser?.user?.ID!,
        date: today,
        ratings: [],
        ...omit(practiceValues, ['__typename', 'patient']),
      },
      thoughtDiaryEntry: {
        event: '',
        thoughtBefore: '',
        feelingsBefore: [],
        behavior: '',
        evidenceFor: [],
        evidenceAgainst: [],
        thoughtAfter: '',
        feelingsAfter: [],
        ...(thoughtDiaryEntry ? omit(thoughtDiaryEntry, ['__typename']) : undefined),
      },
    },
    {
      merger: (originalValue: unknown, formValue: unknown) => {
        if (Array.isArray(originalValue)) {
          return formValue;
        }
        return undefined;
      },
    },
  );

  return (
    <View style={{ flex: 1 }}>
      {isCardView ? (
        <View style={{ flex: 1 }}>
          <View
            pointerEvents="none"
            style={[
              {
                position: 'absolute',
                bottom: 0,
                right: 0,
                left: 0,
              },
            ]}
          >
            <Background
              preserveAspectRatio="xMaxYMin"
              width="100%"
              accessibilityLabel={undefined}
            />
          </View>
          <RoundedSection
            color="#c1e3c9"
            secondaryColor="transparent"
            overflowColor="transparent"
            title={$t({ id: 'EditThoughtDiaryEntry_editThoughtTitle', defaultMessage: 'Thought' })} // TODO
            preview={false}
            testID="EditThoughtDiaryEntry_scrollView"
          >
            <DiaryTabs
              value={props.route.params.step}
              onChangeValue={(newStep) => {
                props.navigation.setParams({ step: newStep });
              }}
              items={[
                {
                  text: $t({ id: 'EditThoughtDiaryEntry_spotTab', defaultMessage: 'Spot it' }),
                  value: 'spot',
                },
                {
                  text: $t({ id: 'EditThoughtDiaryEntry_testTab', defaultMessage: 'Test it' }),
                  value: 'test',
                  disabled: !testItUnlocked,
                  icon: testItUnlocked ? undefined : 'lock',
                },
                {
                  text: $t({ id: 'EditThoughtDiaryEntry_switchTab', defaultMessage: 'Switch it' }),
                  value: 'switch',
                  disabled: !switchItUnlocked,
                  icon: switchItUnlocked ? undefined : 'lock',
                },
              ]}
              style={[
                {
                  borderTopStartRadius: 30,
                  borderTopEndRadius: 30,
                  paddingTop: 10,
                  marginTop: -15,
                },
                {
                  backgroundColor: 'white',
                  marginHorizontal: -20,
                  marginBottom: 20,
                },
              ]}
            />
            <EditThoughtDiaryEntryInner {...props} form={form} />
          </RoundedSection>
        </View>
      ) : (
        <EditThoughtDiaryEntryInner {...props} form={form} />
      )}
      <SafeAreaView
        style={{ backgroundColor: props.route.params.cardStack ? 'white' : Color.grayBackground }}
      >
        <View
          row
          style={{
            borderTopWidth: 1,
            borderTopColor: Color.styleGuide.Gray6,
            paddingVertical: 10,
            paddingHorizontal: 20,
            justifyContent: 'space-between',
          }}
        >
          {isKeyboardOpen ? (
            <>
              <View />
              <Button
                text={$t({
                  id: 'EditThoughtDiaryEntry_closeKeyboardButton',
                  defaultMessage: 'Close keyboard',
                })}
                onPress={Keyboard.dismiss}
                variant="text"
                size="small"
                style={{ paddingVertical: 5.5 }}
                testID="EditThoughtDiaryEntry_closeKeyboardButton"
              />
            </>
          ) : (
            <>
              <Text
                text={$t({
                  id: 'EditThoughtDiaryEntry_oneFormSwitch',
                  defaultMessage: 'Show as one form',
                })}
                weight="semibold"
                accessibilityRole="none"
              />
              <SwitchInput
                accessibilityLabel={$t({
                  id: 'EditThoughtDiaryEntry_oneFormAccessibilityLabel',
                  defaultMessage: 'Show as one form',
                })}
                value={!props.route.params.cardStack}
                onChangeValue={(newValue) => {
                  props.navigation.setParams({ cardStack: !newValue });
                }}
                showOnOff
                testID="EditThoughtDiaryEntry_cardStackSwitch"
              />
            </>
          )}
        </View>
      </SafeAreaView>
    </View>
  );
}
