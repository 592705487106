import { useMutation } from '@apollo/client';

import { AuthScreenContainer } from '@oui/app-core/src/components/AuthScreenContainer';
import { graphql, VariablesOf } from '@oui/lib/src/graphql/tada';
import states from '@oui/lib/src/metadata/states.json';

import { Button } from '@src/components/Button';
import { ErrorPresenter } from '@src/components/ErrorPresenter';
import { PhoneInput } from '@src/components/PhoneInput';
import PickerInput from '@src/components/PickerInput';
import { TextInput } from '@src/components/TextInput';
import { View } from '@src/components/View';
import { useCurrentUser } from '@src/hooks/useCurrentUser';
import { useForm } from '@src/hooks/useForm';
import { logEvent } from '@src/lib/log';
import { StackScreenProps } from '@src/types/navigation';

const FinishPatientRegistrationMutation = graphql(`
  mutation FinishPatientRegistration(
    $name: UserNameInput
    $address: UserAddressInput
    $demo: UserDemoInput
    $phone: String
  ) {
    updateInfo(name: $name, address: $address, demo: $demo, phone: $phone)
  }
`);

export function FinishPatientRegistration(props: StackScreenProps<'FinishPatientRegistration'>) {
  const [finishPatientRegistration] = useMutation(FinishPatientRegistrationMutation);
  const { data: currentUserData } = useCurrentUser();
  const patientData =
    currentUserData?.currentUser?.user?.__typename === 'Patient'
      ? currentUserData.currentUser.user
      : null;
  const { data, bind, validate, humanErrors } = useForm<{
    user: NonNullable<NonNullable<NonNullable<typeof currentUserData>['currentUser']>['user']>;
    address: VariablesOf<typeof FinishPatientRegistrationMutation>['address'];
    phone: VariablesOf<typeof FinishPatientRegistrationMutation>['phone'];
  }>({
    user: { ...currentUserData?.currentUser?.user! },
    address: {
      line1: patientData?.person.address?.[0]?.line?.[0] ?? '',
      line2: patientData?.person.address?.[0]?.line?.[1] ?? '',
      city: patientData?.person.address?.[0]?.city ?? '',
      state: patientData?.person.address?.[0]?.state ?? '',
      zip: patientData?.person.address?.[0]?.postalCode ?? '',
      country: '',
    },
    phone: patientData?.person.phone ?? null,
  });

  async function save() {
    if (!validate()) return;
    logEvent('finish_patient_registration');
    return finishPatientRegistration({
      variables: {
        name: {
          first: data.user.person.givenName ?? '',
          last: data.user.person.familyName ?? '',
        },
        address: data.address,
        phone: data.phone,
      },
    }).then(() => {
      props.navigation.navigate('Confidentiality');
    });
  }

  return (
    <AuthScreenContainer heading="Finish registering" testID="FinishPatientRegistration">
      <View spacing={10}>
        <ErrorPresenter formErrors={humanErrors} />
        <TextInput {...bind(['user', 'person', 'givenName'], { label: 'First name' })} />
        <TextInput {...bind(['user', 'person', 'familyName'], { label: 'Last name' })} />
        <PhoneInput {...bind('phone', { label: 'Phone number' })} />
        <View>
          <TextInput
            {...bind(['address', 'line1'], { label: 'Address' })}
            placeholder="Street address"
          />
          <TextInput
            {...bind(['address', 'line2'], { accessibilityLabel: 'Address Line 2' })}
            placeholder="Address Line 2"
          />
          <View row spacing={12} style={{ flexWrap: 'wrap' }}>
            <TextInput
              {...bind(['address', 'city'], { accessibilityLabel: 'City' })}
              placeholder="City"
              style={{ width: 200 }}
            />
            <PickerInput
              {...bind(['address', 'state'], { accessibilityLabel: 'State' })}
              items={states.map((s) => ({ label: s.name, value: s.abbreviation }))}
              style={{ width: 150 }}
            />
            <TextInput
              {...bind(['address', 'zip'], { accessibilityLabel: 'Zip' })}
              placeholder="Zip"
              style={{ width: 100 }}
            />
          </View>
        </View>
        <Button
          text="Done"
          onPress={save}
          alignSelf="center"
          testID="FinishPatientRegistration_submit"
        />
      </View>
    </AuthScreenContainer>
  );
}
