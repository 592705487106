import { NetworkStatus, useApolloClient, useMutation, useQuery } from '@apollo/client';
import { useFocusEffect, useIsFocused, useNavigation } from '@react-navigation/native';
import endOfWeek from 'date-fns/endOfWeek';
import parseISO from 'date-fns/parseISO';
import startOfWeek from 'date-fns/startOfWeek';
import { LinearGradient } from 'expo-linear-gradient';
import * as Localization from 'expo-localization';
import * as Notifications from 'expo-notifications';
import * as ScreenOrientation from 'expo-screen-orientation';
import noop from 'lodash/noop';
import { memo, useCallback, useEffect, useState } from 'react';
import {
  Platform,
  RefreshControl,
  SafeAreaView,
  StatusBar,
  useWindowDimensions,
} from 'react-native';

import { ActivityDiaryPracticeItem } from '@oui/activity-diary';
import { PracticeItem } from '@oui/app-core/src/components/PracticeItem';
import { SegmentedProgressBar } from '@oui/app-core/src/components/SegmentedProgressBar';
import { SessionCard, SessionCardBlank } from '@oui/app-core/src/components/SessionCard';
import { setDeviceInfo } from '@oui/app-core/src/lib/setDeviceInfo';
import { LearnSessionsFragment } from '@oui/app-core/src/screens/Learn';
import { useTheme } from '@oui/app-core/src/styles';
import { HopeKitPracticeItem } from '@oui/hope-kit';
import { getGQLDate } from '@oui/lib/src/getGQLDate';
import { graphql, ResultOf } from '@oui/lib/src/graphql/tada';
import { OnboardingVariant, ProductVariant } from '@oui/lib/src/types/graphql.generated';
import { RelaxPracticeItem } from '@oui/relax-diary';
import { SleepDiaryPracticeItem, SleepDiaryPracticeItemFragment } from '@oui/sleep-diary';

import { ActivityIndicator } from '@src/components/ActivityIndicator';
import { AnimatedProgressCircle } from '@src/components/AnimatedProgressCircle';
import { Button } from '@src/components/Button';
import { Divider } from '@src/components/Divider';
import { NewPatientSupporterNotification } from '@src/components/NewPatientSupporterNotification';
import { RequiredClinicianUpgrade } from '@src/components/RequiredClinicianUpgrade';
import { ScrollView } from '@src/components/ScrollView';
import { SuicideLifelineCard } from '@src/components/SuicideLifelineCard';
import { TabHeader } from '@src/components/TabHeader';
import { Heading, Text } from '@src/components/Text';
import { View } from '@src/components/View';
import { Environment, environment } from '@src/constants';
import { useCurrentPatient, useProgressByContent } from '@src/hooks/useCurrentUser';
import { useI18n } from '@src/lib/i18n';
import { logEvent } from '@src/lib/log';
import Sentry from '@src/sentry';
import { card, Color } from '@src/styles';
import { ContentType, TabScreenProps } from '@src/types';

type Props = TabScreenProps<'Home'>;

export const HomeFeedV2Query = graphql(
  `
    query HomeFeedV2($progressBefore: Date!, $progressAfter: Date!) {
      user {
        ID
        role {
          ID
          ...LearnSessions
          productConfig {
            ... on RoleAvivaConfig {
              onboardingVariant
            }
          }
        }
      }
      actionTodos {
        todo {
          __typename
          pendingActionID
          ... on PendingThoughtDiaryEntryTestAction {
            thoughtDiaryEntryPractice {
              practiceID
              practiceValues {
                date
              }
            }
          }
          ... on PendingThoughtDiaryEntrySwitchAction {
            thoughtDiaryEntryPractice {
              practiceID
              practiceValues {
                date
              }
            }
          }
          ... on PendingActivityRateAction {
            activityPractice {
              practiceID
              activity {
                title
              }
            }
          }
          ...SleepDiaryPracticeItem
        }
        completed {
          __typename
          actionID
          ... on ThoughtDiaryEntryTestAction {
            thoughtDiaryEntryPractice {
              practiceID
              practiceValues {
                date
              }
            }
          }
          ... on ThoughtDiaryEntrySwitchAction {
            thoughtDiaryEntryPractice {
              practiceID
              practiceValues {
                date
              }
            }
          }
          ... on ActivityRateAction {
            activityPractice {
              practiceID
              activity {
                title
              }
            }
          }
        }
      }
      actionProgresses(after: $progressAfter, before: $progressBefore) {
        date
        completed
        total
      }
    }
  `,
  [LearnSessionsFragment, SleepDiaryPracticeItemFragment],
);

export const SetLocaleAndTimezoneMutation = graphql(`
  mutation SetLocaleAndTimezone($locale: Any!, $timezone: Any!) {
    locale: respond(context: "user", key: "locale", data: $locale)
    timezone: respond(context: "user", key: "timezone", data: $timezone)
  }
`);

const AllDoneRibbon = memo(() => {
  const { $t } = useI18n();
  return (
    <LinearGradient
      colors={['rgba(236, 191, 45, 0)', 'rgba(236, 191, 45, 0.3)']}
      style={[{ marginRight: 30, height: 40, justifyContent: 'center', alignItems: 'center' }]}
      start={[0, 0]}
      end={[1, 0]}
    >
      <Text
        text={$t({ id: 'AllDoneRibbon', defaultMessage: 'All done for today!' })}
        testID="AllDoneRibbon"
        weight="semibold"
        color={Color.styleGuide.Gray3}
      />
      <View
        style={{
          backgroundColor: 'white',
          position: 'absolute',
          transform: [{ rotateZ: '45deg' }],
          width: 40,
          height: 40,
          right: -30,
        }}
      />
    </LinearGradient>
  );
});

const FinishSession1Ribbon = memo(() => {
  const { $t } = useI18n();
  return (
    <LinearGradient
      colors={['rgba(239, 239, 244, 0.5)', 'rgba(29, 103, 208, 0.10)', 'rgba(239, 239, 244, 0.5)']}
      style={[{ height: 40, justifyContent: 'center', alignItems: 'center' }]}
      locations={[0, 0.5, 1]}
      start={[0, 0]}
      end={[1, 0]}
    >
      <Text
        text={$t({
          id: 'FinishSession1Ribbon',
          defaultMessage: 'Finish session 1 to start practicing',
        })}
        testID="FinishSession1Ribbon"
        weight="semibold"
        color={Color.styleGuide.Gray3}
      />
    </LinearGradient>
  );
});

function ActionTodoItem(props: {
  item:
    | ResultOf<typeof HomeFeedV2Query>['actionTodos']['todo'][number]
    | ResultOf<typeof HomeFeedV2Query>['actionTodos']['completed'][number];
}) {
  const { navigate } = useNavigation<TabScreenProps<'Home'>['navigation']>();
  const complete = !props.item.__typename.startsWith('Pending');
  const item = props.item;
  const { $t, formatDate } = useI18n();

  switch (item.__typename) {
    case 'PendingMyPlanReviewAction':
    case 'MyPlanReviewAction':
      return (
        <PracticeItem
          complete={complete}
          icon="my-plan"
          text={$t({ id: 'ActionTodoItem_myPlanReview', defaultMessage: 'Review using MyPlan' })}
          color={Color.styleGuide.LogoLilac}
          onPress={() => navigate('MyPlanReview', {})}
        />
      );
    case 'PendingSleepDiaryEntryNightAction':
    case 'PendingSleepDiaryEntryMorningAction':
      return <SleepDiaryPracticeItem complete={complete} type={item.__typename} data={item} />;
    case 'SleepDiaryEntryNightAction':
    case 'SleepDiaryEntryMorningAction':
      return <SleepDiaryPracticeItem complete={complete} type={item.__typename} data={null} />;
    case 'PendingRelaxAction':
    case 'RelaxAction':
      return <RelaxPracticeItem complete={complete} />;
    case 'PendingThoughtDiaryEntrySwitchAction':
    case 'ThoughtDiaryEntrySwitchAction':
      return (
        <PracticeItem
          complete={complete}
          icon="spot-it"
          text={$t(
            {
              id: 'ActionTodoItem_thoughtDiarySwitch',
              defaultMessage: 'Switch your thought from {date}',
            },
            {
              date: formatDate(parseISO(item.thoughtDiaryEntryPractice.practiceValues.date), {
                month: 'short',
                day: 'numeric',
                weekday: 'short',
              }),
            },
          )}
          color="#104f51"
          onPress={
            item.__typename === 'PendingThoughtDiaryEntrySwitchAction'
              ? () => {
                  navigate('ThoughtDiary', {});
                  navigate('ThoughtDiaryEntry', {
                    practiceID: item.thoughtDiaryEntryPractice.practiceID,
                  });
                }
              : noop
          }
        />
      );
    case 'PendingThoughtDiaryEntrySpotAction':
    case 'ThoughtDiaryEntrySpotAction':
      return (
        <PracticeItem
          complete={complete}
          icon="spot-it"
          text={$t({
            id: 'ActionTodoItem_thoughtDiarySpot',
            defaultMessage: 'Spot a negative thought',
          })}
          color="#104f51"
          onPress={() => {
            navigate('ThoughtDiary', {});
            navigate('EditThoughtDiaryEntry', { step: 'spot', cardStack: true });
          }}
        />
      );
    case 'PendingThoughtDiaryEntryTestAction':
    case 'ThoughtDiaryEntryTestAction':
      return (
        <PracticeItem
          complete={complete}
          icon="spot-it"
          text={$t(
            {
              id: 'ActionTodoItem_thoughtDiaryTest',
              defaultMessage: 'Test your thought from {date}',
            },
            {
              date: formatDate(parseISO(item.thoughtDiaryEntryPractice.practiceValues.date), {
                month: 'short',
                day: 'numeric',
                weekday: 'short',
              }),
            },
          )}
          color="#104f51"
          onPress={
            item.__typename === 'PendingThoughtDiaryEntryTestAction'
              ? () => {
                  navigate('ThoughtDiary', {});
                  navigate('ThoughtDiaryEntry', {
                    practiceID: item.thoughtDiaryEntryPractice.practiceID,
                  });
                }
              : noop
          }
        />
      );
    case 'PendingHopeKitAddAction':
    case 'HopeKitAddAction':
    case 'PendingHopeKitReviewAction':
    case 'HopeKitReviewAction':
      return <HopeKitPracticeItem type={item.__typename} complete={complete} />;
    case 'PendingCopingCardAddAction':
    case 'CopingCardAddAction':
      return (
        <PracticeItem
          complete={complete}
          icon="cards"
          text={$t({
            id: 'ActionTodoItem_copingCardAdd',
            defaultMessage: 'Add to your Coping cards',
          })}
          color="#2461c3"
          onPress={() => {
            navigate('CopingCards', {});
            navigate('EditCopingCards', {});
          }}
        />
      );
    case 'PendingCopingCardReviewAction':
    case 'CopingCardReviewAction':
      return (
        <PracticeItem
          complete={complete}
          icon="cards"
          text={$t({
            id: 'ActionTodoItem_copingCardReview',
            defaultMessage: 'Review your Coping cards',
          })}
          color="#2461c3"
          onPress={() => navigate('CopingCards', {})}
        />
      );
    case 'PendingActivityRateAction':
    case 'ActivityRateAction':
      return (
        <ActivityDiaryPracticeItem
          complete={complete}
          type={item.__typename}
          practice={item.activityPractice}
        />
      );
    case 'PendingActivityAddAction':
    case 'ActivityAddAction':
      return (
        <ActivityDiaryPracticeItem complete={complete} type={item.__typename} practice={null} />
      );
    case 'EatingLogAddAction':
    case 'PendingEatingLogAddAction':
    case 'StaticAction':
      return null;
  }
}

function WeeklyProgress({
  progress,
}: {
  progress?: ResultOf<typeof HomeFeedV2Query>['actionProgresses'];
}) {
  const progressValues = progress?.length
    ? progress.map((p) => (p.total === 0 ? 0 : p.completed / p.total))
    : [0, 0, 0, 0, 0, 0, 0];
  const { formatDate } = useI18n();

  // TODO startOfWeek should be based on locale
  const dates = [
    parseISO('2022-03-20'),
    parseISO('2022-03-21'),
    parseISO('2022-03-22'),
    parseISO('2022-03-23'),
    parseISO('2022-03-24'),
    parseISO('2022-03-25'),
    parseISO('2022-03-26'),
  ];

  return (
    <View row style={{ justifyContent: 'space-between' }} testID="WeeklyProgress">
      {dates.map((date, i) => {
        const dateName = formatDate(date, { weekday: 'long' });
        return (
          <AnimatedProgressCircle
            key={dateName}
            testID={`WeeklyProgress_${i}`}
            text={dateName[0]}
            progress={progressValues[i]}
            accessibilityLabel={dateName}
          />
        );
      })}
    </View>
  );
}

function QuickActions() {
  const { fontScale } = useWindowDimensions();
  const { navigate } = useNavigation<TabScreenProps<'Home'>['navigation']>();
  const { data: progress } = useProgressByContent();
  const [today] = useState(getGQLDate);
  const { $t } = useI18n();
  const { user } = useCurrentPatient();

  const isActivityPlanningLocked = !progress.ACTIVITY_PLANNING?.completed;
  const isSleepDiaryLocked = !progress.SLEEP?.completed;
  const isSpotItLocked = !progress.SPOT_IT?.completed;

  if (user?.role.product.slug === ProductVariant.AVIVA_INPATIENT) {
    return null;
  }

  return (
    <>
      <Heading
        text={$t({ id: 'Home_quickActionsHeading', defaultMessage: 'Add to diaries' })}
        testID="Home_quickActionsHeading"
        style={{ marginTop: 68, marginBottom: 12 }}
        level={2}
      />
      <View
        style={[card, { paddingHorizontal: 15, paddingVertical: 20 }]}
        testID="Home_quickActions"
      >
        <View row={fontScale <= 1} style={{ paddingHorizontal: 10 }} childFlex={1}>
          <View>
            <Button
              variant="text"
              text={$t({
                id: 'Home_quickActionActivityEntryButton',
                defaultMessage: 'Add activity',
              })}
              icon={isActivityPlanningLocked ? 'lock' : 'plus'}
              disabled={isActivityPlanningLocked}
              color={isActivityPlanningLocked ? Color.text : undefined}
              onPress={() => {
                navigate('ActivityDiary', {});
                navigate('EditActivityEvent', {});
              }}
              style={{
                justifyContent: 'flex-start',
              }}
              testID={
                isActivityPlanningLocked
                  ? 'Home_quickActionActivityEntryLocked'
                  : 'Home_quickActionActivityEntry'
              }
            />
          </View>
          <View>
            <Button
              variant="text"
              text={$t({
                id: 'Home_quickActionMorningSleepEntryButton',
                defaultMessage: 'Morning',
              })}
              onPress={() => {
                navigate('SleepDiary', {});
                navigate('EditSleepDiaryEntry', { step: 'morning', date: today });
              }}
              icon={isSleepDiaryLocked ? 'lock' : 'sun'}
              disabled={isSleepDiaryLocked}
              color={isSleepDiaryLocked ? Color.text : undefined}
              style={{}}
              testID={
                isSleepDiaryLocked
                  ? 'Home_quickActionMorningSleepEntryLocked'
                  : 'Home_quickActionMorningSleepEntry'
              }
            />
          </View>
        </View>
        <View row={fontScale <= 1} style={{ paddingHorizontal: 10 }} childFlex={1}>
          <View>
            <Button
              variant="text"
              text={$t({
                id: 'Home_quickActionThoughtEntryButton',
                defaultMessage: 'Add thought',
              })}
              icon={isSpotItLocked ? 'lock' : 'plus'}
              disabled={isSpotItLocked}
              color={isSpotItLocked ? Color.text : undefined}
              onPress={() => {
                navigate('ThoughtDiary', {});
                navigate('EditThoughtDiaryEntry', { step: 'spot', cardStack: true });
              }}
              style={{
                justifyContent: 'flex-start',
                flexBasis: '40%',
              }}
              testID={
                isSpotItLocked
                  ? 'Home_quickActionThoughtEntryLocked'
                  : 'Home_quickActionThoughtEntry'
              }
            />
          </View>
          <View>
            <Button
              variant="text"
              text={$t({
                id: 'Home_quickActionNightSleepEntryButton',
                defaultMessage: 'Night',
              })}
              icon={isSleepDiaryLocked ? 'lock' : 'moon'}
              disabled={isSleepDiaryLocked}
              color={isSleepDiaryLocked ? Color.text : undefined}
              onPress={() => {
                navigate('SleepDiary', {});
                navigate('EditSleepDiaryEntry', { step: 'night', date: today });
              }}
              style={{
                justifyContent: 'flex-start',
                flexBasis: '40%',
              }}
              testID={
                isSleepDiaryLocked
                  ? 'Home_quickActionNightSleepEntryLocked'
                  : 'Home_quickActionNightSleepEntry'
              }
            />
          </View>
        </View>
      </View>
    </>
  );
}

export function HomeV2(props: Props) {
  const progressAfter = getGQLDate(startOfWeek(new Date()));
  const progressBefore = getGQLDate(endOfWeek(new Date()));
  const {
    data: progress,
    loading: progressLoading,
    refetch: refetchProgress,
    networkStatus,
  } = useProgressByContent();
  const apollo = useApolloClient();
  const { user } = useCurrentPatient();
  const { $t } = useI18n();
  const { theme } = useTheme();

  const { data, refetch, loading } = useQuery(HomeFeedV2Query, {
    variables: {
      progressAfter,
      progressBefore,
    },
  });

  const [setLocaleAndTimezone] = useMutation(SetLocaleAndTimezoneMutation);
  useEffect(() => {
    setLocaleAndTimezone({
      variables: { locale: Localization.locale, timezone: Localization.timezone },
    });
  }, [setLocaleAndTimezone]);

  useEffect(() => {
    async function checkPermission() {
      const currentPermissionStatus = await Notifications.getPermissionsAsync();
      if (currentPermissionStatus.granted) return;
      if (currentPermissionStatus.canAskAgain) {
        logEvent('push_permissions_request');
        const newPermissionStatus = await Notifications.requestPermissionsAsync();
        if (newPermissionStatus.granted) {
          logEvent('push_permissions_granted');
          setDeviceInfo(apollo);
        } else {
          logEvent('push_permissions_denied');
        }
      } else {
        logEvent('push_permissions_not_enabled');
        Sentry.captureMessage('Push permissions not granted and cannot ask again', {
          extra: { currentPermissionStatus },
        });
      }
    }
    if (progress.MYPLAN?.completed && Platform.OS !== 'web') {
      checkPermission();
    }
  }, [progress.MYPLAN?.completed, apollo]);

  const isFocused = useIsFocused();

  useFocusEffect(
    useCallback(() => {
      try {
        refetch();
        refetchProgress();
      } catch (e) {
        // HMR error in dev
        if (environment !== Environment.DEVELOPMENT) {
          Sentry.captureException(e);
        }
      }
    }, [refetch, refetchProgress]),
  );

  useFocusEffect(
    useCallback(() => {
      if (Platform.OS !== 'web') {
        ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT_UP);
      }
      return () => {
        if (Platform.OS !== 'web') {
          ScreenOrientation.unlockAsync();
        }
      };
    }, []),
  );

  const myStoryMyPlanCompleteLoaded =
    !progressLoading ||
    // enabling addTypename has caused a slight change in refetch loading state behavior
    // Ideally we don't show the loading state once this data has already loaded for the first time
    networkStatus === NetworkStatus.refetch;

  const item =
    data?.user?.role?.sessions.find((s) => s.status === 'IN_PROGRESS') ||
    data?.user?.role?.sessions.find((s) => s.status === 'UNLOCKED');
  const sessionNum =
    (data?.user?.role?.sessions.findIndex((s) => s.session.sessionID === item?.session.sessionID) ??
      0) + 1;

  const isCollaborativeOnboarding =
    data?.user?.role?.productConfig?.__typename === 'RoleAvivaConfig' &&
    data?.user?.role?.productConfig?.onboardingVariant === OnboardingVariant.COLLABORATIVE;

  if (!myStoryMyPlanCompleteLoaded) {
    return (
      <SafeAreaView>
        <StatusBar backgroundColor={Color.styleGuide.LogoCyan} barStyle="light-content" />
        <View style={{ padding: 50 }}>
          <ActivityIndicator size="large" />
        </View>
      </SafeAreaView>
    );
  }

  let allComplete: boolean = false;
  if (user?.role.product.slug === ProductVariant.AVIVA_INPATIENT) {
    allComplete = !!progress.COPING_CARDS?.completed;
  } else {
    allComplete = !!progress.POST_AVIVA?.completed;
  }

  return (
    <RequiredClinicianUpgrade>
      <View style={{ flex: 1 }}>
        {isFocused ? <StatusBar backgroundColor="white" barStyle="dark-content" /> : null}
        <ScrollView
          refreshControl={
            <RefreshControl
              refreshing={!!(data?.user?.role?.sessions && (loading || progressLoading))}
              onRefresh={() => {
                refetch();
                refetchProgress();
              }}
              title={$t({ id: 'Home_refreshIndicator', defaultMessage: 'Update' })}
              progressViewOffset={150}
            />
          }
          testID="Home_scrollView"
          style={{ flex: 1 }}
          contentContainerStyle={{
            paddingBottom: 20,
            flexGrow: 1,
          }}
          bottomOverflowColor={Color.grayBackground}
        >
          <TabHeader heading={$t({ id: 'Home_heading', defaultMessage: 'Home' })} />
          <View
            style={{
              borderTopWidth: 1,
              borderTopColor: Color.styleGuide.Gray6,
              backgroundColor: Color.grayBackground,
              padding: 20,
              flexGrow: 1,
            }}
          >
            <View
              style={{
                marginBottom: 12,
              }}
            >
              <NewPatientSupporterNotification
                style={{
                  marginBottom: 12,
                }}
              />
              <SuicideLifelineCard />
            </View>
            <View spacing={12}>
              {allComplete ? null : (
                <Heading
                  text={$t({ defaultMessage: 'Next session', id: 'Home_nextSessionHeading' })}
                  testID="Home_nextSessionHeading"
                  level={2}
                />
              )}
              {item ? (
                <View
                  style={{
                    backgroundColor: 'white',
                    borderRadius: 20,
                    paddingHorizontal: 20,
                    paddingVertical: 15,
                    gap: 10,
                  }}
                >
                  <SessionCard
                    testID={`SessionCard_${item.session.contentType}`}
                    state={item.status === 'COMPLETED' ? 'complete' : 'unlocked'}
                    session={{
                      num: sessionNum,
                      details: [], // TODO
                      title: item.session.name,
                      subtitle: item.session.description,
                      illustrationUrl: item.session.illustration?.gcsPath,
                    }}
                    onPressTop={() => {
                      if (
                        isCollaborativeOnboarding &&
                        item.session.contentType === ContentType.MYPLAN
                      ) {
                        props.navigation.navigate('ControlledMyStoryMyPlan');
                      } else {
                        props.navigation.navigate('Conversation', {
                          num: sessionNum.toString(),
                          title: item.session.name,
                          ID: item.session.contentType || 'TEST::convo',
                          completed:
                            item.status === 'COMPLETED' ? ('true' as const) : ('false' as const),
                        });
                      }
                    }}
                  />
                  <View
                    row
                    spacing={10}
                    style={{ marginLeft: 6 }}
                    accessible
                    accessibilityLabel={$t(
                      {
                        id: 'Home_sessionsDoneAccessibilityLabel',
                        defaultMessage:
                          'Session progress: {completedCount} of {totalCount} complete',
                      },
                      {
                        completedCount: sessionNum - 1,
                        totalCount: data?.user?.role?.sessions.length ?? 12,
                      },
                    )}
                  >
                    <Text
                      text={$t({ id: 'Home_sessionsDone', defaultMessage: 'Sessions done' })}
                      weight="semibold"
                      size={15}
                      color={theme.color.gray300}
                    />
                    <Text
                      text={$t(
                        {
                          id: 'Home_sessionProgress',
                          defaultMessage: `{completedCount}/{totalCount}`,
                        },
                        {
                          completedCount: sessionNum - 1,
                          totalCount: data?.user?.role?.sessions.length ?? 12,
                        },
                      )}
                      testID="Home_sessionProgress"
                      size={15}
                      color={theme.color.gray300}
                    />
                    <SegmentedProgressBar
                      length={data?.user?.role?.sessions.length ?? 12}
                      height={6}
                      progress={sessionNum - 1}
                    />
                  </View>
                </View>
              ) : allComplete ? null : (
                <SessionCardBlank />
              )}
              <View>
                <Heading
                  text={$t({ id: 'Home_dailyPracticeHeading', defaultMessage: 'Daily practice' })}
                  testID="Home_dailyPracticeHeading"
                  level={2}
                  style={{ marginTop: allComplete ? 12 : 68, marginBottom: 12 }}
                />
                <View style={[card, { paddingHorizontal: 15, paddingVertical: 20 }]}>
                  <Text
                    text={$t({ id: 'Home_todoListHeading', defaultMessage: 'Up next' })}
                    testID="Home_todoListHeading"
                    color={Color.styleGuide.Gray3}
                    weight="semibold"
                    accessibilityRole="header"
                    style={{ marginBottom: 20 }}
                  />
                  {!progress.MYPLAN?.completed ? (
                    <View style={{ marginHorizontal: -15 }}>
                      <FinishSession1Ribbon />
                    </View>
                  ) : data?.actionTodos.todo.length === 0 ? (
                    <AllDoneRibbon />
                  ) : (
                    <View testID="Home_dailyPracticeList">
                      {data?.actionTodos.todo.map((todo, i, arr) => {
                        return (
                          <View
                            key={todo.pendingActionID}
                            testID={`Home_dailyPracticeList_item_${i}`}
                          >
                            <ActionTodoItem item={todo} />
                            {i === arr.length - 1 ? null : <Divider />}
                          </View>
                        );
                      })}
                    </View>
                  )}
                  {data?.actionTodos.completed.length ? (
                    <Text
                      text="Done"
                      color={Color.styleGuide.Gray3}
                      weight="semibold"
                      accessibilityRole="header"
                      style={{ marginTop: 40, marginBottom: 20 }}
                    />
                  ) : null}
                  {data?.actionTodos.completed.map((todo, i, arr) => {
                    return (
                      <View key={todo.actionID}>
                        <ActionTodoItem item={todo} />
                        {i === arr.length - 1 ? null : <Divider />}
                      </View>
                    );
                  })}
                </View>
                <View style={{ marginTop: 30 }}>
                  <Text
                    text={$t({
                      id: 'Home_weeklyProgressHeading',
                      defaultMessage: 'Weekly progress',
                    })}
                    testID="Home_weeklyProgressHeading"
                    color={Color.styleGuide.Gray3}
                    weight="semibold"
                    accessibilityRole="header"
                    style={{ marginBottom: 20 }}
                  />
                  <WeeklyProgress progress={data?.actionProgresses} />
                </View>
                <QuickActions />
              </View>
            </View>
          </View>
        </ScrollView>
      </View>
    </RequiredClinicianUpgrade>
  );
}
