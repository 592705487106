// This file is automatically generated. See gqlcodegen.yml for details
export const namedAvivaOperations = {
  Query: {
    CMSCopySessionData: 'CMSCopySessionData',
    PatientDevices: 'PatientDevices',
    PatientNotifications: 'PatientNotifications',
    PatientEmails: 'PatientEmails',
    PatientCaringContacts: 'PatientCaringContacts',
    PatientCareTeam: 'PatientCareTeam',
    PatientSupporters: 'PatientSupporters',
    SessionVideos: 'SessionVideos',
    Apps: 'Apps',
    AppByID: 'AppByID',
    CMSIllustrations: 'CMSIllustrations',
    CMSLogos: 'CMSLogos',
    AccountSettings: 'AccountSettings',
    VariantSessionGroups: 'VariantSessionGroups',
    SessionGroupByID: 'SessionGroupByID',
    currentUserId: 'currentUserId',
    HomeFeedV2: 'HomeFeedV2',
    HopeKit: 'HopeKit',
    Learn: 'Learn',
    PatientForMyStoryMyPlan: 'PatientForMyStoryMyPlan',
    AdminOrganization: 'AdminOrganization',
    OrganizationsList: 'OrganizationsList',
    Patient: 'Patient',
    PatientsList: 'PatientsList',
    SuicidalMode: 'SuicidalMode',
    TrialAssignments: 'TrialAssignments',
    UserProfile: 'UserProfile',
    ExchangeActivationToken: 'ExchangeActivationToken',
    CMSSessions: 'CMSSessions',
    CMSVariantByID: 'CMSVariantByID',
    CMSSessionByID: 'CMSSessionByID',
    CMSFileUploadByID: 'CMSFileUploadByID',
    CMSQuizSetCollections: 'CMSQuizSetCollections',
    CMSQuizSetCollectionByID: 'CMSQuizSetCollectionByID'
  },
  Mutation: {
    CMSCopySession: 'CMSCopySession',
    ChatInputMediaPickerUpload: 'ChatInputMediaPickerUpload',
    AddPractitionerToCareTeam: 'AddPractitionerToCareTeam',
    RemovePractitionerFromCareTeam: 'RemovePractitionerFromCareTeam',
    SetCareTeamPrimaryPractitioner: 'SetCareTeamPrimaryPractitioner',
    UpdatePatient: 'UpdatePatient',
    AddContactPointForPatient: 'AddContactPointForPatient',
    AddAddressForPatient: 'AddAddressForPatient',
    AddPatientSupporter: 'AddPatientSupporter',
    IssueTokenLinkForSupporter: 'IssueTokenLinkForSupporter',
    ReviewPatientSupporter: 'ReviewPatientSupporter',
    MarkAsComplete: 'MarkAsComplete',
    CreateCMSApp: 'CreateCMSApp',
    CreateCMSVariant: 'CreateCMSVariant',
    UpdateCMSApp: 'UpdateCMSApp',
    UpdateCMSVariant: 'UpdateCMSVariant',
    CreateFileUpload: 'CreateFileUpload',
    CompleteFileUploadAction: 'CompleteFileUploadAction',
    SaveAccountSettings: 'SaveAccountSettings',
    AddHopeKitImage: 'AddHopeKitImage',
    AddHopeKitVideo: 'AddHopeKitVideo',
    AddHopeKitQuote: 'AddHopeKitQuote',
    CreateCMSSessionGroup: 'CreateCMSSessionGroup',
    UpdateSessionGroup: 'UpdateSessionGroup',
    DeleteSessionGroup: 'DeleteSessionGroup',
    UpdateOrganization: 'UpdateOrganization',
    FinishPatientRegistration: 'FinishPatientRegistration',
    SetLocaleAndTimezone: 'SetLocaleAndTimezone',
    UpdateHopeKitImage: 'UpdateHopeKitImage',
    UpdateHopeKitVideo: 'UpdateHopeKitVideo',
    UpdateHopeKitQuote: 'UpdateHopeKitQuote',
    RemoveHopeKitItem: 'RemoveHopeKitItem',
    AddOrganization: 'AddOrganization',
    AddOrganizationMember: 'AddOrganizationMember',
    UpdateOrganizationMember: 'UpdateOrganizationMember',
    ReplacePrimaryPractitioner: 'ReplacePrimaryPractitioner',
    IssueActivationToken: 'IssueActivationToken',
    AddPatient: 'AddPatient',
    UploadAvatar: 'UploadAvatar',
    AddCMSSession: 'AddCMSSession',
    UpdateCMSSession: 'UpdateCMSSession',
    PatchExchange: 'PatchExchange',
    DeleteSession: 'DeleteSession',
    CreateQuizSetCollection: 'CreateQuizSetCollection',
    UpdateQuizSetCollection: 'UpdateQuizSetCollection',
    CreateQuizSet: 'CreateQuizSet',
    UpdateQuizSet: 'UpdateQuizSet',
    QuizSetItemVideoCreate: 'QuizSetItemVideoCreate',
    QuizSetItemChoiceCreate: 'QuizSetItemChoiceCreate',
    QuizSetItemChoiceUpdate: 'QuizSetItemChoiceUpdate',
    QuizSetItemUpdateOrder: 'QuizSetItemUpdateOrder',
    QuizSetItemDelete: 'QuizSetItemDelete',
    SnapshotSession: 'SnapshotSession',
    CopyQuizSetToCollection: 'CopyQuizSetToCollection',
    DeleteQuizSet: 'DeleteQuizSet'
  },
  Fragment: {
    PatientProfileAdmin: 'PatientProfileAdmin',
    CareTeamFragment: 'CareTeamFragment',
    QuizSet: 'QuizSet'
  }
}