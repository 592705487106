import { ComponentProps, forwardRef, ReactNode } from 'react';
import {
  FlexStyle,
  Platform,
  Text as RNText,
  StyleProp,
  StyleSheet,
  TextStyle,
  View,
} from 'react-native';

import { FontTheme, useTheme } from '../../styles';

type TextAlign = 'auto' | 'left' | 'right' | 'center' | 'justify';

type Props = {
  color?: string;
  italic?: boolean;
  numberOfLines?: number;
  size?: number;
  lineHeight?: number;
  style?: StyleProp<TextStyle>;
  testID?: string;
  text: string | ReactNode;
  textAlign?: TextAlign;
  strikethrough?: boolean;
  weight?: 'normal' | 'bold' | 'semibold';
  children?: ReactNode;
} & Pick<
  ComponentProps<typeof RNText>,
  | 'accessibilityLabel'
  | 'accessibilityRole'
  | 'accessibilityHint'
  | 'accessibilityLiveRegion'
  | 'onLayout'
>;

export const Text = forwardRef<RNText, Props>(function Text(
  {
    accessibilityHint,
    accessibilityLabel,
    accessibilityLiveRegion,
    accessibilityRole,
    children,
    color,
    italic,
    lineHeight,
    numberOfLines,
    onLayout,
    size,
    strikethrough,
    style,
    testID,
    text,
    textAlign,
    weight,
  }: Props,
  ref,
) {
  const { Color, theme } = useTheme();
  const fontSize = size ?? theme.typography.body.size;
  lineHeight =
    typeof size === 'number' && typeof lineHeight !== 'number'
      ? // if size was specified w/o lineHeight, then use our legacy lineHeight calculation
        size * 1.4
      : lineHeight ?? theme.typography.body.lineHeight;

  const inner = (
    <RNText
      accessibilityHint={accessibilityHint}
      accessibilityLabel={accessibilityLabel}
      accessibilityLiveRegion={accessibilityLiveRegion}
      accessibilityRole={accessibilityRole}
      id={testID}
      testID={testID}
      ref={ref}
      numberOfLines={numberOfLines}
      onLayout={onLayout}
      style={[
        {
          textAlign,
          color: color || Color.text,
          fontFamily:
            global.fontsLoaded || Platform.OS === 'web'
              ? weight === 'bold'
                ? 'OpenSansBold'
                : weight === 'semibold'
                  ? 'OpenSansSemiBold'
                  : 'OpenSansRegular'
              : undefined,
          fontSize,
          lineHeight,
        },
        style,
        italic ? { fontStyle: 'italic', fontFamily: undefined } : null,
        strikethrough ? { textDecorationLine: 'line-through', textDecorationStyle: 'solid' } : null,
      ]}
    >
      {text || children || ''}
    </RNText>
  );

  // accessibilityElementsHidden doesn't work on text elements, so if we want to hide this on iOS
  // we need to wrap in a View
  if (accessibilityRole === 'none') {
    return (
      <View
        importantForAccessibility="no-hide-descendants"
        accessibilityElementsHidden
        style={{ flex: StyleSheet.flatten(style)?.flex }}
      >
        {inner}
      </View>
    );
  }

  return inner;
});

export function ThemeText(
  props: {
    fontTheme: FontTheme;
  } & ComponentProps<typeof Text>,
) {
  const fontTheme = props.fontTheme;
  return <Text {...fontTheme} {...props} />;
}

type BasicProps = {
  text: string | ReactNode;
  testID?: string;
  style?: FlexStyle;
  color?: string;
  textAlign?: TextAlign;
} & Pick<
  ComponentProps<typeof RNText>,
  'accessibilityLabel' | 'accessibilityRole' | 'accessibilityHint' | 'accessibilityLiveRegion'
>;

export function Lead(props: BasicProps) {
  const { theme } = useTheme();
  const fontTheme = theme.typography.lead;
  return <ThemeText fontTheme={fontTheme} {...props} />;
}

export function Heading(
  props: {
    level: 1 | 2 | 3;
    variant?: 'dark' | 'light';
  } & BasicProps,
) {
  const { theme } = useTheme();
  const fontTheme = theme.typography[`heading${props.level}`];

  const variantColor = props.variant === 'light' ? theme.color.gray800 : theme.color.dark;

  return (
    <ThemeText
      fontTheme={fontTheme}
      {...props}
      color={props.color ?? fontTheme.color ?? variantColor}
      accessibilityRole="header"
    />
  );
}

export function Label(props: BasicProps & { small?: boolean }) {
  const { theme } = useTheme();
  const fontTheme = theme.typography.label;
  return <ThemeText fontTheme={fontTheme} {...props} size={props.small ? 15 : undefined} />;
}

export function Small(props: BasicProps) {
  const { theme } = useTheme();
  const fontTheme = theme.typography.small;
  return <ThemeText fontTheme={fontTheme} {...props} />;
}

/** @deprecated */
export function OldHeading({
  style,
  ...props
}: {
  text: string;
  style?: StyleProp<TextStyle>;
  textAlign?: TextAlign;
  testID?: string;
}) {
  const { Color } = useTheme();
  return (
    <Text
      {...props}
      size={22}
      lineHeight={28}
      color={Color.tertiary}
      weight="bold"
      style={[{ marginBottom: 4 }, style]}
      accessibilityRole="header"
    />
  );
}

/** @deprecated */
export function OldSubheading({
  style,
  ...props
}: {
  text: string;
  style?: StyleProp<TextStyle>;
  textAlign?: TextAlign;
  testID?: string;
}) {
  return (
    <Text
      {...props}
      size={16}
      lineHeight={18}
      weight="bold"
      style={[{ marginBottom: 4 }, style]}
      accessibilityRole="header"
    />
  );
}
