import { useMutation, useQuery } from '@apollo/client';
import { useActionSheet } from '@expo/react-native-action-sheet';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import * as ImagePicker from 'expo-image-picker';
import * as ScreenOrientation from 'expo-screen-orientation';
import { useCallback } from 'react';
import { Image, Platform, SafeAreaView, TouchableOpacity } from 'react-native';

import { SessionUri } from '@oui/app-core/src/lib/resumableUpload';
import { resumableUploadManager } from '@oui/app-core/src/lib/resumableUploadManager';
import { graphql } from '@oui/lib/src/graphql/tada';
import { ProductVariant } from '@oui/lib/src/types/graphql.generated';

import { ArtifactButton } from '@src/components/ArtifactButton';
import { Icon } from '@src/components/Icon';
import { PatientSupporters } from '@src/components/PatientSupporters';
import { ScreenHeader, SvgWithLogoAndBezierCurve } from '@src/components/ScreenHeader';
import { ScrollView } from '@src/components/ScrollView';
import { Heading, Text } from '@src/components/Text';
import { View } from '@src/components/View';
import { APPROX_STATUSBAR_HEIGHT } from '@src/constants';
import { useMyStoryMyPlanCompositionSections } from '@src/hooks/useComposition';
import { useCurrentPatient, useProgressByContent } from '@src/hooks/useCurrentUser';
import { useI18n } from '@src/lib/i18n';
import Sentry from '@src/sentry';
import { Shadow, useTheme } from '@src/styles';
import { TabScreenProps } from '@src/types';
import { namedAvivaOperations } from '@src/types/namedOperations.generated';

type Props = TabScreenProps<'Profile'>;

export const UserProfileQuery = graphql(`
  query UserProfile {
    currentUser {
      __typename
      ID
      name {
        first
        last
      }
    }
    avatar: asset(context: "user", key: "avatar")
    lessonLearned {
      session
      text
    }
  }
`);

const UploadAvatarMutation = graphql(`
  mutation UploadAvatar {
    uploadResumableAsset(
      input: { context: "user", key: "avatar", fileName: "avatar.jpeg", uploadType: IMAGE }
    ) {
      resumableUploadAssetURI
    }
  }
`);

function V2Records() {
  const { $t } = useI18n();
  const { user } = useCurrentPatient();
  const { data: progress } = useProgressByContent();
  const { navigate } = useNavigation<Props['navigation']>();
  const { data } = useMyStoryMyPlanCompositionSections();
  const hasData = progress.MYPLAN?.completed && !!data;

  const doneText = $t({ id: 'UserProfile_recordComplete', defaultMessage: 'Done' });
  const lockedText = $t({ id: 'UserProfile_recordLocked', defaultMessage: 'Complete session 1' });

  if (
    ([ProductVariant.AVIVA_MILITARY, ProductVariant.AVIVA_INPATIENT] as string[]).includes(
      user?.role.product.slug!,
    )
  ) {
    const suicideMode = $t({
      id: 'UserProfile_suicideModeTitle',
      defaultMessage: 'My Suicide Mode',
    });
    return hasData ? (
      <ArtifactButton
        testID="ArtifactButton_SuicideMode"
        badgeText={doneText}
        assetName="SuicideMode"
        title={suicideMode}
        numCompleted={1}
        onPress={() => navigate('SuicideMode', { step: 'review' })}
      />
    ) : (
      <ArtifactButton
        testID="ArtifactButton_SuicideMode__locked"
        bannerText={lockedText}
        assetName="SuicideMode"
        title={suicideMode}
        numCompleted={0}
        onPress={() => {}}
      />
    );
  }

  const riskCurve = $t({ id: 'UserProfile_riskCurveTitle', defaultMessage: 'Risk Curve' });

  return hasData ? (
    <ArtifactButton
      testID="ArtifactButton_RiskCurve"
      badgeText={doneText}
      assetName="RiskCurve"
      title={riskCurve}
      numCompleted={1}
      onPress={() => navigate('RiskCurve', { step: 'review' })}
    />
  ) : (
    <ArtifactButton
      testID="ArtifactButton_RiskCurve__locked"
      bannerText={lockedText}
      assetName="RiskCurve"
      title={riskCurve}
      numCompleted={0}
      onPress={() => {}}
    />
  );
}

export default function UserProfile(props: Props) {
  const { Color } = useTheme();
  const { $t } = useI18n();
  const { data, refetch } = useQuery(UserProfileQuery);
  const [uploadAvatar] = useMutation(UploadAvatarMutation);
  const { showActionSheetWithOptions } = useActionSheet();
  const user = data && data.currentUser;

  useFocusEffect(
    useCallback(() => {
      if (Platform.OS !== 'web') {
        ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT_UP);
        return () => ScreenOrientation.unlockAsync();
      }

      return;
    }, []),
  );

  return (
    <>
      <SafeAreaView
        style={{
          flex: 1,
          backgroundColor: Color.styleGuide.BrandSpaceBlue,
          paddingTop: APPROX_STATUSBAR_HEIGHT,
        }}
        testID="Profile"
      >
        <ScrollView
          bottomOverflowColor="white"
          style={{ flex: 1 }}
          contentContainerStyle={{ backgroundColor: Color.backgroundColor, paddingBottom: 20 }}
          testID="Profile_scrollView"
        >
          <ScreenHeader hideLogo={true} color={Color.styleGuide.BrandSpaceBlue} />
          <View
            style={{
              zIndex: 2,
              flexDirection: 'row',
              padding: 20,
              paddingTop: Platform.select({ default: 30, android: 60 }),
              paddingBottom: 30,
              alignItems: 'center',
            }}
          >
            <TouchableOpacity
              testID="UserProfile_profilePhotoButton"
              accessibilityLabel={$t({
                id: 'UserProfile_profilePhotoButton',
                defaultMessage: 'Profile photo',
              })}
              accessibilityRole="button"
              onPress={() => {
                showActionSheetWithOptions(
                  {
                    options: [
                      $t({ id: 'UserProfile_choosePhotoCancelOption', defaultMessage: 'Cancel' }),
                      $t({
                        id: 'UserProfile_choosePhotoGalleryOption',
                        defaultMessage: 'Choose from gallery',
                      }),
                      $t({
                        id: 'UserProfile_choosePhotoCameraOption',
                        defaultMessage: 'Take a photo',
                      }),
                    ],
                    cancelButtonIndex: 0,
                  },
                  async (buttonIndex) => {
                    if (typeof buttonIndex !== 'number') return;
                    if (buttonIndex > 0) {
                      const useGallery = buttonIndex === 1;
                      const [{ status }] = await Promise.all(
                        useGallery
                          ? [ImagePicker.requestMediaLibraryPermissionsAsync()]
                          : [
                              ImagePicker.requestCameraPermissionsAsync(),
                              ImagePicker.requestMediaLibraryPermissionsAsync(),
                            ],
                      );

                      if (status === 'granted') {
                        const options = {
                          mediaTypes: ImagePicker.MediaTypeOptions.Images,
                          allowsEditing: false,
                          quality: 1,
                        };
                        const promise = global.e2e
                          ? Promise.resolve<ImagePicker.ImagePickerResult>({
                              canceled: false,
                              assets: [
                                {
                                  uri:
                                    Platform.OS === 'ios'
                                      ? // For some reason iOS can't fetch picsum.photos, but android can
                                        // Web can't fetch placedog.net b/c of a CORS issue
                                        'https://placedog.net/500/500'
                                      : 'https://picsum.photos/500',
                                  width: 500,
                                  height: 500,
                                },
                              ],
                            })
                          : useGallery
                            ? ImagePicker.launchImageLibraryAsync(options)
                            : ImagePicker.launchCameraAsync(options);

                        promise.then((result) => {
                          if (!result.canceled) {
                            uploadAvatar({
                              refetchQueries: [namedAvivaOperations.Query.UserProfile],
                            })
                              .then(async (avatarResult) => {
                                const id = await resumableUploadManager.uploadFile(
                                  result.assets![0].uri,
                                  avatarResult.data?.uploadResumableAsset
                                    .resumableUploadAssetURI as SessionUri,
                                );
                                const remove = resumableUploadManager.addListener(
                                  id,
                                  ({ percent }) => {
                                    if (percent === 100) {
                                      refetch();
                                      remove();
                                    }
                                  },
                                );
                              })
                              .catch(Sentry.captureException);
                          }
                        });
                      }
                    }
                  },
                );
              }}
            >
              <Icon
                name="camera"
                color="#666"
                style={{
                  marginLeft: 50,
                  marginTop: 90,
                  position: 'absolute',
                  zIndex: 10,
                }}
              />
              {data?.avatar ? (
                <Image
                  style={{
                    width: 120,
                    height: 120,
                    borderRadius: 60,
                    backgroundColor: Color.styleGuide.BrandPastelPink,
                  }}
                  source={{ uri: data?.avatar }}
                  testID="UserProfile_avatar"
                />
              ) : (
                <View
                  style={{
                    width: 120,
                    height: 120,
                    borderRadius: 60,
                    backgroundColor: Color.styleGuide.BrandPastelPink,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Text
                    color={Color.styleGuide.BrandCarminePink}
                    text={user ? `${user.name.first[0] ?? ''}${user.name.last[0] ?? ''}` : ''}
                    size={45}
                    weight="bold"
                    style={{ lineHeight: 61 }}
                  />
                </View>
              )}
            </TouchableOpacity>
            <Text
              text={user ? `${user.name.first} ${user.name.last}` : ''}
              weight="bold"
              size={30}
              lineHeight={38}
              style={{
                marginLeft: 20,
                flex: 1,
                flexWrap: 'wrap',
              }}
            />
            <Icon
              testID="UserProfile_settingsButton"
              accessibilityLabel={$t({
                id: 'UserProfile_settingsButton',
                defaultMessage: 'Settings',
              })}
              name="settings"
              color="#666"
              onPress={() =>
                props.navigation.navigate('AccountSettings', {
                  isEditing: 'false',
                })
              }
              style={{ alignSelf: 'flex-start' }}
            />
          </View>
          <View
            style={{
              paddingHorizontal: 20,
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginBottom: 12,
            }}
          >
            <Heading
              text={$t({
                id: 'UserProfile_lessonsLearnedHeading',
                defaultMessage: 'Lessons Learned',
              })}
              level={2}
            />
            {!data?.lessonLearned?.length ? null : (
              <Icon
                name="edit"
                onPress={() => props.navigation.navigate('EditLessonsLearned')}
                accessibilityLabel={$t({
                  id: 'UserProfile_editLessonsLearnedButton',
                  defaultMessage: 'Edit lessons learned',
                })}
                color={Color.styleGuide.Gray4}
                testID="UserProfile_editLessonsLearnedButton"
              />
            )}
          </View>
          <View style={{ backgroundColor: '#2461c3' }}>
            <View
              style={{ position: 'absolute', bottom: 0, right: 0, left: 0 }}
              pointerEvents="none"
            >
              <SvgWithLogoAndBezierCurve
                inverse
                viewBoxWidth={100}
                viewBoxHeight={50}
                startPoint={[-30, 0]}
                firstControlPoint={[0, 30]}
                secondControlPoint={[70, 60]}
                endPoint={[100, 40]}
                fill={Color.surfaceColor}
              />
            </View>
            <View
              style={[
                {
                  alignSelf: 'stretch',
                  marginVertical: 30,
                  marginHorizontal: 20,
                  paddingBottom: 10,
                  backgroundColor: data?.lessonLearned ? Color.surfaceColor : Color.grayBackground,
                  borderRadius: 16,
                },
                data?.lessonLearned ? Shadow.default : null,
              ]}
            >
              {/* NB use an inner wrapper view for overflow behavior because the outer will hide shadow */}
              <View
                style={{
                  overflow: 'hidden',
                }}
              >
                <View style={{ flexGrow: 1, padding: 20 }} spacing={15}>
                  <Text
                    text={$t({
                      id: 'UserProfile_lessonsLearnedListHeading',
                      defaultMessage: 'Lessons from each session',
                    })}
                    weight="semibold"
                    color={Color.styleGuide.Gray4}
                  />
                  {!data?.lessonLearned?.length ? (
                    <View row spacing={12}>
                      <Icon name="lock" size={16} />
                      <Text
                        text={$t({
                          id: 'UserProfile_lessonsLearnedLocked',
                          defaultMessage: 'Complete session 1',
                        })}
                        size={13}
                        accessibilityLabel={$t({
                          id: 'UserProfile_lessonsLearnedLockedAccessibilityLabel',
                          defaultMessage: 'Complete session 1 to unlock',
                        })}
                      />
                    </View>
                  ) : (
                    data?.lessonLearned?.map(({ text, session }, i) => {
                      return (
                        <View key={session}>
                          <View style={{ flexDirection: 'row' }}>
                            <Text
                              text={(i + 1).toString()}
                              style={{ minWidth: 20 }}
                              textAlign="right"
                            />
                            <View style={{ marginStart: 12, marginEnd: 12 }}>
                              <Text
                                text={text}
                                weight="semibold"
                                testID={`UserProfile_lessonLearned_${session}`}
                              />
                            </View>
                          </View>
                        </View>
                      );
                    })
                  )}
                </View>
              </View>
            </View>
          </View>
          <>
            <Heading
              level={2}
              text={$t({
                id: 'UserProfile_recordsHeading',
                defaultMessage: 'Records',
              })}
              style={{
                paddingHorizontal: 20,
                marginBottom: 12,
              }}
            />
            <View style={{ paddingHorizontal: 20 }}>
              <V2Records />
              <PatientSupporters
                style={{
                  marginTop: 25,
                }}
              />
            </View>
          </>
        </ScrollView>
      </SafeAreaView>
    </>
  );
}
