import { useQuery } from '@apollo/client';
import { useFocusEffect, useIsFocused } from '@react-navigation/native';
import * as Sentry from '@sentry/core';
import * as ScreenOrientation from 'expo-screen-orientation';
import { Fragment, useCallback } from 'react';
import { Platform, RefreshControl, StatusBar } from 'react-native';

import { graphql, ResultOf } from '@oui/lib/src/graphql/tada';

import { ActivityIndicator } from '../components/ActivityIndicator';
import { LearnProgress } from '../components/LearnProgress';
import { ScrollHere, ScrollView } from '../components/ScrollView';
import { SessionCard } from '../components/SessionCard';
import { TabHeader } from '../components/TabHeader';
import { Heading } from '../components/Text';
import { View } from '../components/View';
import { Environment, environment } from '../constants';
import { useI18n } from '../lib/i18n';

export const LearnSessionsFragment = graphql(`
  fragment LearnSessions on Role @_unmask {
    ID
    sessions {
      session {
        sessionID
        order
        name
        description
        contentType
        agenda
        illustration {
          fileUploadID
          gcsPath
        }
        completedIllustration {
          fileUploadID
          gcsPath
        }
        group {
          groupID
          name
        }
      }
      status
      unlockedAt
    }
  }
`);

export const LearnQuery = graphql(
  `
    query Learn {
      user {
        ID
        role {
          ID
          ...LearnSessions
        }
      }
    }
  `,
  [LearnSessionsFragment],
);

export type SessionWithState = NonNullable<
  NonNullable<ResultOf<typeof LearnQuery>['user']>['role']
>['sessions'][number];

export function Learn(props: {
  progressBackgroundColor?: string;
  onSessionCardPress: (value: SessionWithState, sessionNum: number) => void;
  paddingBottom?: number;
}) {
  const { data, refetch, loading } = useQuery(LearnQuery);
  const isFocused = useIsFocused();
  const { $t } = useI18n();

  useFocusEffect(
    useCallback(() => {
      if (Platform.OS !== 'web') {
        void ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT_UP);
      }

      try {
        refetch();
      } catch (e) {
        // HMR error in dev
        if (environment !== Environment.DEVELOPMENT) {
          Sentry.captureException(e);
        }
      }

      return () => {
        if (Platform.OS !== 'web') {
          void ScreenOrientation.unlockAsync();
        }
      };
    }, [refetch]),
  );

  const sessions = data?.user?.role?.sessions;
  const numTotal = sessions?.length || 12;
  const numCompleted = sessions?.filter((s) => s.status === 'COMPLETED').length || 0;
  let hasSeenActive = false;

  return (
    <View style={{ flex: 1 }}>
      {isFocused ? <StatusBar backgroundColor="white" barStyle="dark-content" /> : null}
      <ScrollView
        refreshControl={
          <RefreshControl
            refreshing={!!(sessions && loading)}
            onRefresh={() => {
              refetch();
            }}
            title={$t({ id: 'Learn_refreshIndicator', defaultMessage: 'Update' })}
            progressViewOffset={150}
          />
        }
        testID="Learn_scrollView"
        style={{ flex: 1 }}
        contentContainerStyle={{
          paddingHorizontal: 20,
          paddingBottom: props.paddingBottom ?? 20,
        }}
      >
        <TabHeader heading={$t({ id: 'Learn_heading', defaultMessage: 'Learn' })} />
        <LearnProgress
          backgroundColor={props.progressBackgroundColor}
          numCompleted={numCompleted}
          numTotal={numTotal}
        />
        <View style={{ gap: 10, marginTop: 20 }}>
          {sessions ? (
            sessions.map((session, i, arr) => {
              const prev = arr[i - 1] as (typeof arr)[number] | undefined;
              const slug = session.session.contentType;
              const state = session.status;
              const item = session.session;
              const details = item.agenda;
              const newGroup = !!(
                session.session.group &&
                session.session.group.groupID !== prev?.session.group?.groupID
              );

              const sessionState = (
                {
                  LOCKED: 'locked',
                  // If unlocked and no previous session is active then this should show as active
                  UNLOCKED: hasSeenActive ? 'unlocked' : 'active',
                  COMPLETED: 'complete',
                  IN_PROGRESS: 'active',
                } as const
              )[state];

              if (sessionState === 'active') hasSeenActive = true;

              return (
                <Fragment key={session.session.sessionID}>
                  {sessionState === 'active' &&
                  // don't need to scroll if we're the first session
                  i !== 0 ? (
                    <View style={{ marginTop: 20 }}>
                      <ScrollHere offsetY={0} />
                    </View>
                  ) : null}
                  {newGroup && session.session.group ? (
                    <View style={{ marginTop: i === 0 ? undefined : 50 }}>
                      <Heading text={session.session.group.name} level={2} />
                    </View>
                  ) : null}
                  <SessionCard
                    testID={`SessionCard_${slug}`}
                    state={sessionState}
                    session={{
                      num: i + 1,
                      details,
                      title: item.name,
                      subtitle: item.description,
                      illustrationUrl:
                        sessionState === 'complete'
                          ? item.completedIllustration?.gcsPath || item.illustration?.gcsPath
                          : item.illustration?.gcsPath,
                    }}
                    unlockedAt={prev?.status === 'COMPLETED' ? session.unlockedAt : undefined}
                    onPressTop={() => {
                      props.onSessionCardPress(session, i + 1);
                    }}
                  />
                </Fragment>
              );
            })
          ) : (
            <ActivityIndicator />
          )}
        </View>
      </ScrollView>
    </View>
  );
}
