import { ComponentProps } from 'react';

import { Checkbox } from '../components/Checkbox';
import { Text } from '../components/Text';
import { TextInput } from '../components/TextInput';
import { View } from '../components/View';
import { InputRenderer } from '../form';
import { AccessibleInput } from '../hooks/useAccessibleInput';
import { Color } from '../styles';

type Props<T extends string> = {
  label?: string;
  labelSize?: number;
  labelWeight?: ComponentProps<typeof Checkbox>['labelWeight'];
  optionLabelWeight?: ComponentProps<typeof Checkbox>['labelWeight'];
  checkboxSize?: ComponentProps<typeof Checkbox>['size'];
  hideHint?: boolean;
  accessibilityLabel?: string;
  error?: string;
  items: Partial<Record<T, string>>;
  customLabels?: { [key: string]: JSX.Element };
  value: T[];
  onChangeValue: (newValue: T[]) => void;
  testID?: string;
  disabled?: boolean;
} & (
  | {
      defaultFollowup?: string;
      followups: Record<string, string>;
      followupsValue: Record<string, string>;
      onChangeFollowupsValue: (newFollowups: Record<string, string>) => void;
    }
  | {
      defaultFollowup?: never;
      followups?: never;
      followupsValue?: never;
      onChangeFollowupsValue?: never;
    }
);

export function CheckboxListInput<T extends string>(props: Props<T>) {
  return (
    <AccessibleInput
      accessibilityLabel={props.accessibilityLabel ?? props.label!}
      label={
        props.label
          ? () => (
              <View style={{ marginLeft: -16, marginBottom: props.hideHint ? 5 : 20 }} spacing={10}>
                <Text
                  accessibilityRole="none"
                  text=""
                  weight="semibold"
                  size={props.labelSize ?? 21}
                >
                  {props.label?.replace(/\*$/, '')}
                  {props.label?.endsWith('*') ? (
                    <Text text=" *" color={Color.error} weight="semibold" />
                  ) : null}
                </Text>
                {props.hideHint ? null : <Text text="Check any that apply" />}
              </View>
            )
          : undefined
      }
      labelWeight={props.labelWeight}
      testID={props.testID}
      error={props.error}
    >
      {(accessibleProps) => (
        <View
          {...accessibleProps}
          style={{ gap: 10 }}
          accessibilityRole="combobox"
          accessibilityHint="Check any that apply"
          accessibilityState={{ disabled: props.disabled }}
          testID={props.testID}
        >
          {Object.entries(props.items).map((item: any, i) => {
            const [key, label]: [T, (typeof props)['items'][T]] = item;
            const isChecked = props.value.includes(key);
            const question = props.followups?.[key] ?? props.defaultFollowup;

            return (
              <View key={key}>
                <Checkbox
                  horizontal
                  onChangeValue={(newValue) =>
                    props.onChangeValue(
                      newValue ? [...props.value, key] : props.value.filter((v) => v !== key),
                    )
                  }
                  label={props?.customLabels?.[label as string] || label}
                  labelWeight={props.optionLabelWeight ?? props.labelWeight}
                  size={props.checkboxSize}
                  value={isChecked}
                  testID={`${props.testID}_choice_${i}`}
                  disabled={props.disabled}
                />
                {isChecked && question ? (
                  <View style={{ marginLeft: 32 }}>
                    <TextInput
                      testID={`${props.testID}_choice_${i}_followupInput`}
                      placeholder={question}
                      value={props.followupsValue?.[key]}
                      onChangeValue={(newV) => {
                        const value = { ...props.followupsValue, [key]: newV };
                        if (newV) {
                          props.onChangeFollowupsValue?.(value);
                        } else {
                          delete value[key];
                          props.onChangeFollowupsValue?.(value);
                        }
                      }}
                      disabled={props.disabled}
                    />
                  </View>
                ) : null}
              </View>
            );
          })}
        </View>
      )}
    </AccessibleInput>
  );
}

export const CheckboxListInputRender: InputRenderer<ComponentProps<typeof CheckboxListInput>> = (
  renderProps,
  props,
) => {
  const {
    field: { onChange, onBlur, value, name },
    fieldState: { error },
  } = renderProps;
  return (
    <CheckboxListInput
      testID={`FormInput_${name.replaceAll('.', '_')}`}
      onChangeValue={(v) => {
        onChange(v);
        onBlur();
      }}
      value={value}
      error={error?.message}
      {...props}
    />
  );
};
