import hexToRgba from 'hex-to-rgba';
import { ComponentProps, memo, ReactNode, useEffect, useRef, useState } from 'react';
import { Animated, Easing, Platform, Pressable } from 'react-native';
import { v4 as uuid } from 'uuid';

import { Icon } from '../components/Icon';
import { Text, ThemeText } from '../components/Text';
import { View } from '../components/View';
import { useIsSafeLayoutAnimating } from '../hooks/useSafeLayoutAnimation';
import { Theme, useTheme } from '../styles';

type Props = {
  accessibilityLabel?: string;
  disabled?: boolean;
  horizontal?: boolean;
  label?: string | ReactNode;
  labelFont?: keyof Theme['typography'];
  labelWeight?: ComponentProps<typeof Text>['weight'];
  onChangeValue: (value: boolean) => void;
  size?: 'small' | 'large';
  testID?: string;
  value?: boolean;
  variant?: 'radio' | 'checkbox';
};

// CheckboxInner is purely a perf optimization. onChangeValue is often the
// prop that changes but only affects the TouchableWithoutFeedback wrapper component
// If that changes, we dont care about rerendering the visible portion of the component
const CheckboxInner = memo(function CheckboxInner({
  accessibilityLabel,
  horizontal,
  label,
  labelWeight,
  labelFont,
  size: _size = 'large',
  testID,
  value,
  variant,
}: Omit<Props, 'onChangeValue'>) {
  const isAnimating = useIsSafeLayoutAnimating();
  const { theme, Color } = useTheme();
  const animValue = useRef(new Animated.Value(value ? 1 : 0));
  useEffect(() => {
    Animated.timing(animValue.current, {
      useNativeDriver: Platform.OS !== 'web',
      toValue: value ? 1 : 0,
      duration: 150,
      easing: Easing.inOut(Easing.linear),
    }).start();
  }, [animValue, value]);

  const fontTheme = theme.typography[labelFont ?? theme.checkbox.optionLabel];
  const size = _size === 'small' ? theme.checkbox.sizeSmall : theme.checkbox.size;

  return (
    <>
      {label ? (
        <ThemeText
          text={label}
          accessibilityRole={Platform.OS === 'web' ? ('label' as 'text') : 'text'}
          accessibilityLabel={accessibilityLabel}
          testID={testID}
          style={{
            flex: horizontal ? 1 : 0,
            marginBottom: horizontal ? 0 : 4,
            marginLeft: horizontal ? theme.checkbox.optionLabelSpacing : 0,
          }}
          fontTheme={fontTheme}
          weight={labelWeight ?? fontTheme.weight ?? 'semibold'}
        />
      ) : null}
      <View style={{ height: Math.max(fontTheme.lineHeight, size), justifyContent: 'center' }}>
        <View
          key={isAnimating.toString()}
          style={[
            {
              alignItems: 'center',
              borderColor: theme.color.primary100,
              borderRadius: variant === 'radio' ? 15 : 4,
              borderWidth: 2,
              height: size,
              justifyContent: 'center',
              overflow: variant === 'radio' ? 'hidden' : undefined,
              width: size,
            },
          ]}
        >
          <Animated.View
            style={{
              alignItems: 'center',
              backgroundColor: hexToRgba(theme.color.primary100),
              height: size - 3,
              justifyContent: 'center',
              opacity: isAnimating ? (value ? 1 : 0) : animValue.current,
              width: size - 3,
            }}
            // @ts-expect-error
            dataSet={{ noselect: '' }}
          >
            {variant === 'radio' ? (
              <View
                style={{
                  backgroundColor: 'transparent',
                  borderColor: Color.backgroundColor,
                  borderWidth: 3,
                  height: size - 2,
                  width: size - 2,
                  borderRadius: 13,
                }}
              />
            ) : (
              <Animated.View
                style={{
                  transform: [{ scale: isAnimating ? (value ? 1 : 0) : animValue.current }],
                }}
              >
                <Icon name="check" size={size - 10} color="white" />
              </Animated.View>
            )}
          </Animated.View>
        </View>
      </View>
    </>
  );
});

export function Checkbox({ onChangeValue, testID, ...props }: Props) {
  const [defaultTestID] = useState(uuid());
  testID = testID || defaultTestID;
  return (
    <Pressable
      testID={testID}
      disabled={props.disabled}
      onPress={(e) => {
        if (Platform.OS === 'web') {
          e.preventDefault();
        }
        onChangeValue(!props.value);
      }}
      hitSlop={{
        top: 8,
        left: 8,
        bottom: 8,
        right: 8,
      }}
      accessibilityRole={props.variant === 'radio' ? 'radio' : 'checkbox'}
      accessibilityState={{
        disabled: props.disabled,
        checked: props.value,
      }}
      aria-labelledby={testID ? `${testID}_label` : undefined}
    >
      <View
        row={props.horizontal}
        style={[
          props.horizontal
            ? {
                flexDirection: 'row-reverse',
                alignItems: 'flex-start',
              }
            : null,
          props.disabled ? { opacity: 0.5 } : null,
        ]}
      >
        <CheckboxInner {...props} testID={testID ? `${testID}_label` : undefined} />
      </View>
    </Pressable>
  );
}
