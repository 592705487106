import { CrisisTimelineList } from '@oui/myplan/src/components/CrisisTimelineList';

import { PatientMyStoryMyPlanContainer } from '@src/components/PatientMyStoryMyPlanContainer';
import { Text } from '@src/components/Text';
import { View } from '@src/components/View';
import {
  useMyStoryMyPlanCompositionSections,
  useMyStoryMyPlanCompositionSectionSubscriptionData,
} from '@src/hooks/useComposition';
import { useI18n } from '@src/lib/i18n';
import { useTheme } from '@src/styles';

function Dot() {
  return (
    <View
      style={{
        width: 10,
        height: 10,
        borderRadius: 5,
        backgroundColor: '#dee0e5',
      }}
    />
  );
}

function EmptyState() {
  const { $t } = useI18n();
  const { Color } = useTheme();
  return (
    <View row spacing={20} style={{ flexGrow: 1, alignItems: 'stretch' }}>
      <View style={{ alignItems: 'center' }}>
        <Dot />
        <View style={{ width: 1, backgroundColor: Color.styleGuide.Gray6, flex: 1 }} />
      </View>
      <Text
        text={$t({
          id: 'PatientMyStoryTimeline_description',
          defaultMessage:
            "To check I understand, I'm going to summarize some of the main points from your story.",
        })}
        color={Color.styleGuide.Gray4}
        textAlign="center"
        style={{ maxWidth: 300, paddingHorizontal: 30 }}
      />
    </View>
  );
}

export function PatientMyStoryTimeline(props: { review?: boolean }) {
  const { $t } = useI18n();
  const { data } = useMyStoryMyPlanCompositionSectionSubscriptionData();
  return (
    <PatientMyStoryMyPlanContainer
      heading={$t({
        id: 'PatientMyStoryTimeline_heading',
        defaultMessage: 'Suicide crisis timeline',
      })}
      testID="PatientMyStoryNarrative"
    >
      <View style={{ paddingTop: 14, paddingHorizontal: 30, flexGrow: 1 }}>
        {props.review || !data?.CRISIS_TIMELINE ? (
          <EmptyState />
        ) : (
          <CrisisTimelineList {...data?.CRISIS_TIMELINE} showRiskColor={false} />
        )}
      </View>
    </PatientMyStoryMyPlanContainer>
  );
}

export function PatientMyStoryTimelineStatic(props: { review?: boolean }) {
  const { $t } = useI18n();
  const { data } = useMyStoryMyPlanCompositionSections();
  return (
    <PatientMyStoryMyPlanContainer
      heading={$t({
        id: 'PatientMyStoryTimeline_heading',
        defaultMessage: 'Suicide crisis timeline',
      })}
      testID="PatientMyStoryNarrative"
    >
      <View style={{ paddingVertical: 14, paddingHorizontal: 30, flexGrow: 1 }}>
        {props.review || !data?.CRISIS_TIMELINE ? (
          <EmptyState />
        ) : (
          <CrisisTimelineList {...data?.CRISIS_TIMELINE} showRiskColor={false} />
        )}
      </View>
    </PatientMyStoryMyPlanContainer>
  );
}
