import { useQuery } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { StyleSheet } from 'react-native';

import { graphql } from '@oui/lib/src/graphql/tada';
import { encodeHostedQuizSetSlug } from '@oui/lib/src/quizSetSlug';
import type { ContentType } from '@oui/lib/src/types';
import { GQLUUID } from '@oui/lib/src/types/scalars';

import { useI18n } from '../../../lib/i18n';
import type { StackScreenProps } from '../../../types/navigation';
import { ActivityIndicator } from '../../ActivityIndicator';
import ChatArtifactPreview from '../../ChatArtifactPreview';
import { ErrorPresenter } from '../../ErrorPresenter';
import { Heading } from '../../Text';
import { View } from '../../View';

export type SessionVideosProps = {
  testID?: string;
  contentType: ContentType;
};

export const SessionVideosQuery = graphql(`
  query SessionVideos($contentType: String!) {
    user {
      ID
      role {
        ID
        sessions(contentType: $contentType) {
          session {
            sessionID
            content {
              activities {
                name
                params
              }
              quizSetCollections {
                quizSetCollectionID
                name
                sets {
                  ID
                  description
                  items {
                    quizSetItemID
                    ... on QuizSetItemVideo {
                      fileUpload {
                        fileName
                        fileUploadID
                        fileUploadType
                        gcsPath
                        mimeType
                      }
                      url
                    }
                  }
                  legacyItems {
                    ... on LegacyQuizSetItemVideo {
                      props {
                        url
                        title
                        compulsory
                      }
                    }
                  }
                  name
                  previewOptions {
                    fitMode
                    height
                    time
                  }
                  previewTime
                  quizSetID
                }
              }
              contentTypes
            }
          }
        }
      }
    }
  }
`);

export function SessionVideos({ testID, contentType }: SessionVideosProps) {
  const { $t } = useI18n();
  const { navigate } = useNavigation<StackScreenProps<'Conversation'>['navigation']>();

  const { data, loading, error } = useQuery(SessionVideosQuery, {
    variables: {
      contentType,
    },
    fetchPolicy: 'cache-only',
  });

  const collections = data?.user?.role?.sessions[0]?.session.content?.quizSetCollections;

  return (
    <View
      testID={testID}
      style={[
        {
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'space-between',
        },
      ]}
      spacing={15}
    >
      <Heading
        level={2}
        text={$t({ id: 'Conversation_videoTabVideosHeadline', defaultMessage: 'Videos' })}
      />
      {loading ? (
        <View
          style={{
            flex: 1,
            marginVertical: 30,
            flexWrap: 'wrap',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-around',
          }}
        >
          <ActivityIndicator size="large" style={StyleSheet.absoluteFillObject} />
        </View>
      ) : (
        <View
          style={[
            {
              flex: 1,
              flexWrap: 'wrap',
              flexDirection: 'row',
              justifyContent: 'space-between',
              margin: 5,
            },
          ]}
        >
          {error ? <ErrorPresenter error={error} /> : null}
          {collections?.map((collection, i) => (
            <View
              key={collection.quizSetCollectionID}
              style={{ marginBottom: i < collections.length - 2 ? 40 : 0 }}
            >
              <ChatArtifactPreview
                _width={159}
                _height={177}
                _onSideEffect={() => {
                  navigate('QuizSet', {
                    slug: encodeHostedQuizSetSlug({
                      quizSetCollectionID: collection.quizSetCollectionID as GQLUUID,
                    }),
                  });
                }}
                artifactName="QuizSet"
                params={{
                  slug: encodeHostedQuizSetSlug({
                    quizSetCollectionID: collection.quizSetCollectionID as GQLUUID,
                  }),
                }}
                linkText={collection.name}
              />
            </View>
          ))}
          {(collections?.length ?? 0) % 2 === 1 ? <View style={{ width: 159 }} /> : null}
        </View>
      )}
    </View>
  );
}
