import { PickerProps as RNPickerProps } from '@react-native-picker/picker';
import noop from 'lodash/noop';
import { ComponentProps, useMemo } from 'react';
import { Platform, StyleProp, ViewStyle } from 'react-native';
import RNPickerSelect, { PickerSelectProps as PickerProps } from 'react-native-picker-select';

import { Icon } from '../components/Icon';
import { getStyles } from '../components/TextInput';
import { AccessibleInput } from '../hooks/useAccessibleInput';
import { useTheme } from '../styles';

export function PickerInput<T extends string | number | undefined>({
  accessibilityLabel,
  error,
  label,
  labelHorizontal,
  onChangeValue,
  placeholder,
  style,
  testID,
  variant,
  required,
  disabled,
  ...props
}: Omit<PickerProps, 'placeholder' | 'onValueChange' | 'style' | 'items'> & {
  items: {
    label: string;
    value: T | undefined;
  }[];
  accessibilityLabel?: string;
  error?: string;
  testID?: string;
  placeholder?: string;
  variant?: 'contained' | 'flat';
  label?: string;
  labelHorizontal?: ComponentProps<typeof AccessibleInput>['labelHorizontal'];
  disabled?: boolean;
  style?: StyleProp<ViewStyle>;
  onChangeValue?: (value: T) => void;
  required?: boolean;
}) {
  const { theme, Color } = useTheme();
  const baseInputStyle = getStyles(theme, Color)[variant === 'flat' ? 'flat' : 'outlined'];
  const inputStyle = error ? { ...baseInputStyle, borderColor: Color.error } : baseInputStyle;
  const selectedLabel = useMemo(() => {
    return props.items.find((item) => item.value === props.value)?.label;
  }, [props.items, props.value]);

  const items: (typeof props)['items'] = useMemo(() => {
    return props.items.map((i) => ({ ...i, key: i.value?.toString() }));
  }, [props.items]);

  return (
    <AccessibleInput
      accessibilityLabel={accessibilityLabel}
      error={error}
      label={label}
      labelHorizontal={labelHorizontal}
      style={style}
      testID={testID}
      required={required}
      disabled={disabled}
    >
      {(accessibleProps) => (
        <RNPickerSelect
          {...props}
          items={items}
          pickerProps={
            {
              testID: testID,
              accessibilityLabel: accessibleProps.accessibilityLabel,
              'aria-disabled': disabled,
            } satisfies RNPickerProps
          }
          useNativeAndroidPickerStyle={false}
          touchableWrapperProps={{
            ...accessibleProps,
            accessible: true,
            accessibilityLabel: `Dropdown list. ${selectedLabel ? `${selectedLabel}.` : ''} ${
              accessibleProps.accessibilityLabel ?? ''
            } Double tap to change`,
            testID: testID ? `${testID}_touchable` : undefined,
          }}
          textInputProps={{
            importantForAccessibility: 'no',
          }}
          touchableDoneProps={{
            testID: testID ? `${testID}_doneButton` : undefined,
          }}
          placeholder={placeholder ? { label: placeholder, value: '', color: '#9191A3' } : {}}
          onValueChange={
            onChangeValue
              ? (value, idx) => {
                  const newValue =
                    value === '' ? undefined : props.items[placeholder ? idx - 1 : idx].value;
                  // This callback runs if props.value changes triggering a change in the underlying component
                  // However, if props.value is already equal to the new value, there is no point in
                  // calling onChangeValue
                  if (newValue !== props.value) {
                    onChangeValue(newValue as T);
                  }
                }
              : noop
          }
          style={{
            inputIOS: inputStyle,
            inputAndroid: inputStyle,
            iconContainer: {
              top: 2,
              bottom: 0,
              justifyContent: 'center',
              right: 16,
              ...(Platform.OS === 'web' ? { pointerEvents: 'none' } : undefined),
            },
            headlessAndroidPicker:
              Platform.OS === 'web'
                ? ({
                    color: Color.text,
                  } as any) // eslint-disable-line
                : undefined,
            placeholder: {
              color: '#9191A3',
            },
          }}
          Icon={
            <Icon
              accessibilityLabel={undefined}
              name="caret-down"
              size={14}
              color={Color.styleGuide.Gray4}
            />
          }
        />
      )}
    </AccessibleInput>
  );
}
